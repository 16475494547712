import { useContext } from 'react';
import AuthContext from 'src/contexts/auth-context';

export default function useAuthContext() {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error(
      'useAuthContext must be used within Auth component defined in src/components/auth',
    );
  }

  return context;
}
