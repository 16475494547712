import { useEffect } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import useAuthContext from 'src/hooks/use-auth-context';

export default function LaunchDarkly() {
  const client = useLDClient();
  const { user } = useAuthContext();

  useEffect(() => {
    if (user) {
      client?.identify({
        kind: 'user',
        key: user.id,
        email: user.email,
        name: user.name,
        agencyId: user.defaultAgencyId ?? '',
      });
    }
  }, [client, user]);

  return null;
}
