import * as React from 'react';
import {
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from '@material-ui/core/styles';
import {
  Box,
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  DialogProps as MuiDialogProps,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2, 3),
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    closeButton: {
      color: theme.palette.grey[500],
    },
  });

type DialogTitleProps = WithStyles<typeof styles> & {
  children: React.ReactNode;
  onClose?: () => void;
};

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Box flex={1}>{children}</Box>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="small"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
    paddingBottom: 0,
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(3),
  },
}))(MuiDialogActions);

const DialogActionsWithDivider = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(3),
    boxShadow: '4px 0px 8px 0px #3350A11A',
    borderTop: '1px solid #E6E7EF',
  },
}))(MuiDialogActions);

type DialogProps = Omit<MuiDialogProps, 'onClose' | 'title'> & {
  /** Heading title. Can be a text or any valid ReactNode. */
  title?: React.ReactNode;
  /** ReactNode for primary submit button in Dialog Actions. */
  primaryAction?: React.ReactNode;
  /** ReactNode for secondary cancel/close button in Dialog Actions. */
  secondaryAction?: React.ReactNode;
  /** Whether to close Modal onClick of background */
  disableBackdropClose?: boolean;
  /** Callback invoked when cancel/close button is clicked. */
  onClose?: () => void;
  actionDivider?: boolean;
};

/**
 * Common dialog/modal component. Extends MUI Dialog (Refer: https://next.material-ui.com/components/dialogs)
 * @component
 *
 * @example
 * const handleClose = () => {
 *   // Do something here to close the dialog
 * };
 * return <Dialog onClose={handleClose} onSubmit={handleSubmit} />
 */
export default function Dialog({
  title,
  children,
  open,
  onClose,
  primaryAction,
  secondaryAction,
  disableBackdropClose,
  actionDivider,
  ...rest
}: DialogProps) {
  return (
    <MuiDialog
      onClose={disableBackdropClose ? () => null : onClose}
      open={open}
      {...rest}
    >
      {!!title && <DialogTitle onClose={onClose}>{title}</DialogTitle>}
      <DialogContent>{children}</DialogContent>
      {!!primaryAction || !!secondaryAction ? (
        <>
          {actionDivider ? (
            <DialogActionsWithDivider>
              {!!secondaryAction && secondaryAction}
              {!!primaryAction && primaryAction}
            </DialogActionsWithDivider>
          ) : (
            <DialogActions>
              {!!secondaryAction && secondaryAction}
              {!!primaryAction && primaryAction}
            </DialogActions>
          )}
        </>
      ) : null}
    </MuiDialog>
  );
}
