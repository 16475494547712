import { AttachmentList } from '@nirvana/ui-kit';
import { FileUploadProgress } from './FileUploadBox';
import { FieldSet } from './FieldSet';

type Props = {
  title: string;
  files: FileUploadProgress[];
  onRemove: (progress?: FileUploadProgress, index?: number) => void;
  onCancel: (progress: FileUploadProgress, index: number) => void;
  onRetry: (progress: FileUploadProgress, index: number) => void;
  onDownload: (progress: FileUploadProgress, index: number) => void;
};

export const FileList = ({
  title,
  files,
  onRemove,
  onCancel,
  onRetry,
  onDownload,
}: Props) => {
  return (
    <FieldSet title={title}>
      <AttachmentList
        files={files.map((record: FileUploadProgress) => ({
          filename: record.name,
          status: record.status,
          progress:
            record && record.total
              ? ((record.loaded || 0) * 100) / (record.total || 1)
              : undefined,
        }))}
        onRemove={(_, index) => onRemove(files[index], index)}
        onCancel={(_, index) => onCancel(files[index], index)}
        onRetry={(_, index) => onRetry(files[index], index)}
        onDownload={(_, index) => onDownload(files[index], index)}
        mode="list"
      />
    </FieldSet>
  );
};
