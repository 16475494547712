import { createContext } from 'react';
import { UseMutateFunction } from 'react-query';
import {
  LoginRequest,
  LoginResponse,
  UserProfileResponse,
} from '@nirvana/api/auth';

const AuthContext = createContext<{
  user?: UserProfileResponse | null;
  error: unknown;
  loading: boolean;
  login: UseMutateFunction<LoginResponse, unknown, LoginRequest, unknown>;
  logout: () => void;
}>({
  user: null,
  error: null,
  loading: false,
  login: () => {},
  logout: () => {},
});

export default AuthContext;
