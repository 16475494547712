import { AuthApi, Configuration, LoginRequest } from '@nirvana/api/auth';
import { cookieStorage } from '@nirvana/ui-kit';
import { OAuthConnectionData } from 'src/../../api/quoting';
import apiService from '../utils/auth-api-service';

export const fetchUserProfile = async () => {
  const apiKey = cookieStorage.get({ key: import.meta.env.VITE_AUTH_TOKEN });
  if (apiKey) {
    const configOptions = new Configuration({ apiKey });
    const authenticatedApiService = new AuthApi(configOptions);
    const { data } = await authenticatedApiService.meGet();
    return data;
  }
  return null;
};

export const fetchUserProfileByEmail = async (credentials: LoginRequest) => {
  const configOptions = new Configuration();
  const apiService = new AuthApi(configOptions);
  const { data } = await apiService.authLoginPost(credentials);

  return data;
};

export const postGmailAuthToken = async (token: OAuthConnectionData) => {
  const { data } = await apiService.googleAuthPost(token);
  return data;
};

export const fetchGmailProfile = async () => {
  const { data } = await apiService.googleAuthGet();
  return data;
};

export const fetchCallbackURL = async () => {
  const { data } = await apiService.googleAuthStartPost();
  return data;
};
