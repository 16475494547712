import { CircularProgress } from '@material-ui/core';
import { cookieStorage } from '@nirvana/ui-kit';
import { ReactNode, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import AuthContext from 'src/contexts/auth-context';
import { fetchUserProfile, fetchUserProfileByEmail } from 'src/queries/auth';

type AuthProps = {
  children: ReactNode;
};

export default function Auth({ children }: AuthProps) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(false);

  const { data: user } = useQuery('auth', fetchUserProfile, {
    onSettled: () => setIsAuthenticated(true),
    retry: false,
  });

  const queryClient = useQueryClient();

  const { mutate: login, error } = useMutation(fetchUserProfileByEmail, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: (data) => {
      cookieStorage.set({
        key: import.meta.env.VITE_AUTH_TOKEN,
        domain: import.meta.env.VITE_AUTH_COOKIE_DOMAIN,
        value: data.sessionId,
        days: 30 * 365,
      });

      // Invalidate and refetch the user profile
      queryClient.invalidateQueries('auth');
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  function logout() {
    cookieStorage.remove({
      key: import.meta.env.VITE_AUTH_TOKEN,
      domain: import.meta.env.VITE_AUTH_COOKIE_DOMAIN,
    });
    // React Query will re-run fetchUserProfile and get `user` as null
    queryClient.invalidateQueries('auth');
    queryClient.removeQueries();
  }

  if (!isAuthenticated) {
    return (
      <div className="flex items-center justify-center w-screen h-screen">
        <CircularProgress className="text-secondary-light" />
      </div>
    );
  }

  return (
    <AuthContext.Provider value={{ user, error, loading, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
}
