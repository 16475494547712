import * as React from 'react';
import MUIButton, { ButtonProps } from '@material-ui/core/Button';

interface IProps extends ButtonProps {
  children: React.ReactNode;
}

/**
 * Common button UI component, which is an extension of Mui Button
 * Refer: https://next.material-ui.com/components/buttons for usage and props
 * @component
 *
 * @param {Object} props - Material UI button props.
 * @param {Object} props.children - Text or any valid ReactNode.
 *
 * @example
 * return <Button variant="contained">Submit</Button>
 */
const Button: React.FC<IProps> = ({ children, ...rest }) => {
  return <MUIButton {...rest}>{children}</MUIButton>;
};

export default Button;
