import { constants } from '@nirvana/ui-kit';

export type Step =
  | 'reporter-information'
  | 'incident-details'
  | 'review-and-submit';

export const STEPS: { key: Step; label: string }[] = [
  { key: 'reporter-information', label: 'Reporter Information' },
  { key: 'incident-details', label: 'Incident Details' },
  { key: 'review-and-submit', label: 'Review & Submit' },
];

export const noticeTypeMapping = {
  initiateClaim: 'Initiate Claim',
  onlyReporting: 'Only Reporting',
};

export const noticeTypeOptions = Object.keys(noticeTypeMapping).map((key) => ({
  label: noticeTypeMapping[key as keyof typeof noticeTypeMapping],
  value: key,
}));

export const usStatesMapping: { [key: string]: string } =
  constants.usStates.reduce((acc: { [key: string]: string }, current) => {
    acc[current.code] = current.name;
    return acc;
  }, {});

type TPolicyCoveragesMapping = Record<string, string>;

export const policyCoveragesMapping: TPolicyCoveragesMapping = {
  CoverageAutoLiability: 'Auto Liability',
  CoverageGeneralLiability: 'General Liability',
  CoverageMotorTruckCargo: 'Motor Truck Cargo',
  CoverageAutoPhysicalDamage: 'Auto Physical Damage',
};
