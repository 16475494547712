import * as React from 'react';
import {
  Box,
  CircularProgress,
  circularProgressClasses,
  IconButton,
  Theme,
} from '@material-ui/core';

import IconUploadCancel from '../../assets/icons/upload-cancel.svg';

export interface IAttachmentProgressProps {
  value: number;
  onClick: () => void;
}

const AttachmentProgress = ({ value, onClick }: IAttachmentProgressProps) => {
  return (
    <Box sx={{ position: 'relative' }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme: Theme) => theme.palette.grey[200],
        }}
        size={32}
        thickness={3}
        value={100}
      />
      <CircularProgress
        sx={{
          color: (theme: Theme) => theme.palette.info.main,
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        variant="determinate"
        value={value}
        size={32}
        thickness={3}
      />
      <IconButton
        sx={{ position: 'absolute', left: 0, padding: '11px' }}
        onClick={onClick}
      >
        <img src={IconUploadCancel} alt="Cancel upload" />
      </IconButton>
    </Box>
  );
};

export default AttachmentProgress;
