const cookieStorage = {
  // Set a cookie
  set: ({
    key,
    value,
    days,
    domain,
    secure = true,
  }: {
    key: string;
    value: string;
    days?: number;
    domain?: string;
    secure?: boolean;
  }) => {
    let cookieString = `${key}=${value};path=/;SameSite=Lax;`;

    if (domain) {
      cookieString += `domain=${domain};`;
    }

    if (days) {
      const expires = new Date();
      expires.setDate(expires.getDate() + days);
      cookieString += `expires=${expires.toUTCString()};`;
    }

    if (secure) {
      cookieString += 'secure;';
    }

    document.cookie = cookieString;
  },

  // Get a cookie by key
  get: ({ key }: { key: string }) => {
    const name = `${key}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookies = decodedCookie.split(';');

    for (let cookie of cookies) {
      cookie = cookie.trim();
      if (cookie.startsWith(name)) {
        return cookie.substring(name.length);
      }
    }
    return null;
  },

  // Remove a cookie
  remove: ({
    key,
    domain,
    secure = true,
  }: {
    key: string;
    domain?: string;
    secure?: boolean;
  }) => {
    let cookieString = `${key}=;path=/;expires=Thu, 01 Jan 1970 00:00:00 GMT;SameSite=Lax;`;

    if (domain) {
      cookieString += `domain=${domain};`;
    }

    if (secure) {
      cookieString += 'secure;';
    }

    document.cookie = cookieString;
  },
};

export default cookieStorage;
