import { useEffect } from 'react';
import LogRocket from 'logrocket';
import useAuthContext from 'src/hooks/use-auth-context';

export default function Logrocket() {
  const { user } = useAuthContext();

  useEffect(() => {
    if (user) {
      LogRocket.init(import.meta.env.VITE_LOGROCKET_KEY);
      LogRocket.identify(user.id, { name: user.name, email: user.email });
    }
  }, [user]);

  return null;
}
