import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Time: string;
  bool: boolean;
  float64: number;
  int: number;
  int64: number;
  string: string;
};

export type Address = {
  __typename?: 'Address';
  city: Scalars['string'];
  state: Scalars['string'];
  street1: Scalars['string'];
  street2?: Maybe<Scalars['string']>;
  zip: Scalars['string'];
};

export type Address_InputObject = {
  city: Scalars['string'];
  state: Scalars['string'];
  street1: Scalars['string'];
  street2?: InputMaybe<Scalars['string']>;
  zip: Scalars['string'];
};

export enum AgencyRole {
  /** Accounting */
  Accounting = 'Accounting',
  /** Billing */
  Billing = 'Billing',
  /** BrandMarketer */
  BrandMarketer = 'BrandMarketer',
  /** Claims */
  Claims = 'Claims',
  /** ContentDistributor */
  ContentDistributor = 'ContentDistributor',
  /** CustomerService */
  CustomerService = 'CustomerService',
  /** DirectBilling */
  DirectBilling = 'DirectBilling',
  /** Leadership */
  Leadership = 'Leadership',
  /** LegalOrCompliance */
  LegalOrCompliance = 'LegalOrCompliance',
  /** Marketer */
  Marketer = 'Marketer',
  /** Producer */
  Producer = 'Producer',
  /** RiskServices */
  RiskServices = 'RiskServices',
  /** Safety */
  Safety = 'Safety',
}

export type BasicScoreDetail = {
  __typename?: 'BasicScoreDetail';
  measure?: Maybe<Scalars['float64']>;
  name: Scalars['string'];
  percentile?: Maybe<Scalars['int64']>;
  threshold: Scalars['float64'];
};

export enum Category {
  /** ControlledSubstancesAlcohol */
  ControlledSubstancesAlcohol = 'ControlledSubstancesAlcohol',
  /** CrashIndicator */
  CrashIndicator = 'CrashIndicator',
  /** DriverFitness */
  DriverFitness = 'DriverFitness',
  /** HMCompliance */
  HmCompliance = 'HMCompliance',
  /** HOSCompliance */
  HosCompliance = 'HOSCompliance',
  /** InsuranceOther */
  InsuranceOther = 'InsuranceOther',
  /** UnsafeDriving */
  UnsafeDriving = 'UnsafeDriving',
  /** Unspecified */
  Unspecified = 'Unspecified',
  /** VehicleMaintenance */
  VehicleMaintenance = 'VehicleMaintenance',
}

export type Chart = {
  __typename?: 'Chart';
  geoJSON?: Maybe<GeoJson>;
  projection?: Maybe<LineChart>;
  table?: Maybe<Table>;
  title?: Maybe<Scalars['string']>;
  url?: Maybe<Scalars['string']>;
};

export type ClaimFeedback = {
  __typename?: 'ClaimFeedback';
  category: FeedbackCategory;
  claimId: Scalars['string'];
  createdAt: Scalars['Time'];
  createdBy: Scalars['string'];
  id: Scalars['string'];
  rating: Scalars['int'];
  value?: Maybe<Scalars['string']>;
};

export enum ClaimStatus {
  /** Closed */
  Closed = 'Closed',
  /** CreatedInError */
  CreatedInError = 'CreatedInError',
  /** Invalid */
  Invalid = 'Invalid',
  /** Open */
  Open = 'Open',
  /** Reopen */
  Reopen = 'Reopen',
}

export type ClaimVehicle_InputObject = {
  registrationNumber?: InputMaybe<Scalars['string']>;
  vin?: InputMaybe<Scalars['string']>;
};

export type Data = {
  __typename?: 'Data';
  xVal?: Maybe<Scalars['string']>;
  yVals: Array<Scalars['float64']>;
};

export type DeleteSentInspectionsResponse = {
  __typename?: 'DeleteSentInspectionsResponse';
  deletedCount: Scalars['int64'];
};

export enum DotRating {
  /** ConditionalRating */
  ConditionalRating = 'ConditionalRating',
  /** SatisfactoryRating */
  SatisfactoryRating = 'SatisfactoryRating',
  /** Unrated */
  Unrated = 'Unrated',
  /** UnsatisfactoryRating */
  UnsatisfactoryRating = 'UnsatisfactoryRating',
}

export type DraftFnol = {
  __typename?: 'DraftFnol';
  attachments: Array<DraftFnolAttachment>;
  contacts: Array<DraftFnolContact>;
  createdAt: Scalars['Time'];
  createdBy: Scalars['string'];
  fnolId?: Maybe<Scalars['string']>;
  id: Scalars['string'];
  incidentDescription?: Maybe<Scalars['string']>;
  injuriesInvolved?: Maybe<Scalars['bool']>;
  lossDatetime?: Maybe<Scalars['Time']>;
  lossLocation?: Maybe<Scalars['string']>;
  lossState?: Maybe<Scalars['string']>;
  noticeType?: Maybe<Scalars['int']>;
  policeAgencyName?: Maybe<Scalars['string']>;
  policeReportNumber?: Maybe<Scalars['string']>;
  policyNumber?: Maybe<Scalars['string']>;
  updatedAt: Scalars['Time'];
  vehicles: Array<DraftFnolVehicle>;
};

export type DraftFnolAttachment = {
  __typename?: 'DraftFnolAttachment';
  createdAt: Scalars['Time'];
  draftFnolId: Scalars['string'];
  handleId: Scalars['string'];
  id: Scalars['string'];
};

export type DraftFnolContact = {
  __typename?: 'DraftFnolContact';
  contactType?: Maybe<Scalars['int']>;
  createdAt: Scalars['Time'];
  draftFnolId: Scalars['string'];
  email?: Maybe<Scalars['string']>;
  firstName?: Maybe<Scalars['string']>;
  id: Scalars['string'];
  lastName?: Maybe<Scalars['string']>;
  phone?: Maybe<Scalars['string']>;
  updatedAt: Scalars['Time'];
};

export type DraftFnolVehicle = {
  __typename?: 'DraftFnolVehicle';
  createdAt: Scalars['Time'];
  draftFnolId: Scalars['string'];
  id: Scalars['string'];
  isInsuredVehicle?: Maybe<Scalars['bool']>;
  registrationNumber?: Maybe<Scalars['string']>;
  updatedAt: Scalars['Time'];
  vIN?: Maybe<Scalars['string']>;
};

export enum FeedbackCategory {
  /** FasterClaimResolution */
  FasterClaimResolution = 'FasterClaimResolution',
  /** FrequentAdjusterCommunication */
  FrequentAdjusterCommunication = 'FrequentAdjusterCommunication',
  /** Invalid */
  Invalid = 'Invalid',
  /** OfferFairerCompensation */
  OfferFairerCompensation = 'OfferFairerCompensation',
  /** Other */
  Other = 'Other',
  /** OutstandingService */
  OutstandingService = 'OutstandingService',
  /** ProvideBetterSupport */
  ProvideBetterSupport = 'ProvideBetterSupport',
  /** RequireFewerDocuments */
  RequireFewerDocuments = 'RequireFewerDocuments',
}

export enum FlagCategory {
  /** BASICS */
  Basics = 'BASICS',
  /** Crashes */
  Crashes = 'Crashes',
  /** DOTRating */
  DotRating = 'DOTRating',
  /** Insurance */
  Insurance = 'Insurance',
  /** RelatedEntities */
  RelatedEntities = 'RelatedEntities',
  /** Violations */
  Violations = 'Violations',
}

export type FlagConnection = {
  __typename?: 'FlagConnection';
  edges: Array<FlagEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['int64'];
};

export type FlagEdge = {
  __typename?: 'FlagEdge';
  cursor: Scalars['string'];
  node: Flag;
};

export enum FlagSeverity {
  /** Critical */
  Critical = 'Critical',
  /** Minor */
  Minor = 'Minor',
  /** Moderate */
  Moderate = 'Moderate',
}

export type FleetSafetyReportConnection = {
  __typename?: 'FleetSafetyReportConnection';
  edges: Array<FleetSafetyReportEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['int64'];
};

export type FleetSafetyReportEdge = {
  __typename?: 'FleetSafetyReportEdge';
  cursor: Scalars['string'];
  node: FleetSafetyReport;
};

export type FleetSearchResult = {
  __typename?: 'FleetSearchResult';
  dotNumber: Scalars['string'];
  lastViewedAt?: Maybe<Scalars['Time']>;
  name: Scalars['string'];
  state?: Maybe<Scalars['string']>;
};

export type Fnol = {
  __typename?: 'Fnol';
  attachments: Array<FnolAttachment>;
  clientClaimNumber: Scalars['string'];
  contacts: Array<FnolContact>;
  createdAt: Scalars['Time'];
  createdBy: Scalars['string'];
  id: Scalars['string'];
  incidentDescription: Scalars['string'];
  injuriesInvolved: Scalars['bool'];
  lossDatetime: Scalars['Time'];
  lossLocation: Scalars['string'];
  lossState: Scalars['string'];
  nonVehiclePropertyDamageDescription?: Maybe<Scalars['string']>;
  noticeType: FnolNoticeType;
  policeAgencyName?: Maybe<Scalars['string']>;
  policeReportNumber?: Maybe<Scalars['string']>;
  policyNumber: Scalars['string'];
  status: FnolStatus;
  vehicles: Array<FnolVehicle>;
};

export type FnolAttachment = {
  __typename?: 'FnolAttachment';
  fnolId: Scalars['string'];
  handleId: Scalars['string'];
  id: Scalars['string'];
};

export type FnolContact = {
  __typename?: 'FnolContact';
  contactType: Scalars['int'];
  email?: Maybe<Scalars['string']>;
  firstName: Scalars['string'];
  fnolId: Scalars['string'];
  id: Scalars['string'];
  lastName: Scalars['string'];
  phone: Scalars['string'];
};

export enum FnolNoticeType {
  /** Claim */
  Claim = 'Claim',
  /** Invalid */
  Invalid = 'Invalid',
  /** Report */
  Report = 'Report',
}

export enum FnolStatus {
  /** Declined */
  Declined = 'Declined',
  /** Invalid */
  Invalid = 'Invalid',
  /** Processed */
  Processed = 'Processed',
  /** Sent */
  Sent = 'Sent',
}

export type FnolVehicle = {
  __typename?: 'FnolVehicle';
  fnolId: Scalars['string'];
  id: Scalars['string'];
  isInsuredVehicle: Scalars['bool'];
  registrationNumber?: Maybe<Scalars['string']>;
  vIN?: Maybe<Scalars['string']>;
};

export type GeoFeature = {
  __typename?: 'GeoFeature';
  geometry?: Maybe<Geometry>;
  properties?: Maybe<Properties>;
  type?: Maybe<Scalars['string']>;
};

export type GeoJson = {
  __typename?: 'GeoJSON';
  features: Array<GeoFeature>;
};

export type Geometry = {
  __typename?: 'Geometry';
  coordinates: Array<Array<Array<Scalars['float64']>>>;
  type: Scalars['string'];
};

export type IssScore = {
  __typename?: 'ISSScore';
  discount?: Maybe<Scalars['float64']>;
  errorMessage?: Maybe<Scalars['string']>;
  hardBrakingCountPer1000Miles?: Maybe<Scalars['float64']>;
  hardBrakingPercentile?: Maybe<Scalars['float64']>;
  month: Scalars['int'];
  speedingCountPer1000Miles?: Maybe<Scalars['float64']>;
  speedingPercentile?: Maybe<Scalars['float64']>;
  value?: Maybe<Scalars['float64']>;
  year: Scalars['int'];
};

export type InitiateDriverViolationFetchResults = {
  __typename?: 'InitiateDriverViolationFetchResults';
  browserWSEndpoint: Scalars['string'];
  twoFactorUrl: Scalars['string'];
};

export enum InspectionLevel {
  /** DriverOnly */
  DriverOnly = 'DriverOnly',
  /** Full */
  Full = 'Full',
  /** Material */
  Material = 'Material',
  /** SpecialStudy */
  SpecialStudy = 'SpecialStudy',
  /** Terminal */
  Terminal = 'Terminal',
  /** Unknown */
  Unknown = 'Unknown',
  /** WalkAround */
  WalkAround = 'WalkAround',
}

export type InspectionRecordConnection = {
  __typename?: 'InspectionRecordConnection';
  edges: Array<InspectionRecordEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['int64'];
};

export type InspectionRecordEdge = {
  __typename?: 'InspectionRecordEdge';
  cursor: Scalars['string'];
  node: Inspection;
};

export type InsuranceRecord = {
  __typename?: 'InsuranceRecord';
  carrier: Scalars['string'];
  effectiveDateFrom: Scalars['Time'];
  effectiveDateTo?: Maybe<Scalars['Time']>;
  policyNumber?: Maybe<Scalars['string']>;
  status?: Maybe<Scalars['string']>;
  types: Scalars['string'];
};

export type LineChart = {
  __typename?: 'LineChart';
  data: Array<Data>;
  lineConfigs: Array<LineConfig>;
  threshold?: Maybe<Scalars['float64']>;
  xField?: Maybe<Scalars['string']>;
  xLabel?: Maybe<Scalars['string']>;
  yLabel?: Maybe<Scalars['string']>;
};

export type LineConfig = {
  __typename?: 'LineConfig';
  dashed?: Maybe<Scalars['bool']>;
  name?: Maybe<Scalars['string']>;
};

export enum LineOfBusiness {
  /** AutoLiability */
  AutoLiability = 'AutoLiability',
  /** GeneralLiability */
  GeneralLiability = 'GeneralLiability',
  /** Invalid */
  Invalid = 'Invalid',
  /** MotorTruckCargo */
  MotorTruckCargo = 'MotorTruckCargo',
}

export type Location = {
  __typename?: 'Location';
  countyCode?: Maybe<Scalars['string']>;
  countyName?: Maybe<Scalars['string']>;
};

export type MonthlyValues = {
  __typename?: 'MonthlyValues';
  mileage?: Maybe<Scalars['float64']>;
  month: Scalars['int'];
  pU?: Maybe<Scalars['float64']>;
  utilization?: Maybe<Scalars['float64']>;
  year: Scalars['int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  activateUser?: Maybe<ActivateUserResponse>;
  completeDriverViolationFetch: Scalars['bool'];
  createAgencyAndBDMapping?: Maybe<Agency>;
  createClaimFeedback?: Maybe<ClaimFeedback>;
  createFNOL?: Maybe<Fnol>;
  createFleetSafetyReport?: Maybe<FleetSafetyReport>;
  createRole?: Maybe<Role>;
  createUser?: Maybe<User>;
  deactivateUser: Scalars['bool'];
  deleteRole: Scalars['bool'];
  deleteSentInspections: DeleteSentInspectionsResponse;
  finalizeTelematics?: Maybe<FinalizeTelematicsResponse>;
  initiateTelematics?: Maybe<InitiateTelematicsResponse>;
  inviteAgencyUserFromAgents?: Maybe<AgencyUserInvite>;
  inviteAgencyUserFromSupport?: Maybe<AgencyUserInvite>;
  inviteFleetUser?: Maybe<FleetUserInvite>;
  patchAgency?: Maybe<Agency>;
  patchUser?: Maybe<User>;
  printFleetSafetyReport?: Maybe<PrintFleetSafetyReportLink>;
  sendClaimSummary: Scalars['bool'];
  setFleetStarredStatus: Scalars['bool'];
  shareSafetyReport?: Maybe<SafetyReportShare>;
  updateAgentDetail: Scalars['bool'];
  updateAgentLicense: Scalars['bool'];
  updateUserNotificationPreferences: Array<UserWorkflowPreference>;
};

export type MutationActivateUserArgs = {
  email: Scalars['string'];
  firstName: Scalars['string'];
  lastName: Scalars['string'];
  password: Scalars['string'];
  phoneNumber?: InputMaybe<Scalars['string']>;
  profilePicture?: InputMaybe<Scalars['string']>;
  title?: InputMaybe<Scalars['string']>;
};

export type MutationCompleteDriverViolationFetchArgs = {
  browserWSEndpoint: Scalars['string'];
  reportId: Scalars['string'];
  twoFA: Scalars['string'];
  twoFactorUrl: Scalars['string'];
};

export type MutationCreateAgencyAndBdMappingArgs = {
  address: Address_InputObject;
  bDUserID: Scalars['string'];
  name: Scalars['string'];
};

export type MutationCreateClaimFeedbackArgs = {
  category: FeedbackCategory;
  claimId: Scalars['string'];
  rating: Scalars['int'];
  value?: InputMaybe<Scalars['string']>;
};

export type MutationCreateFnolArgs = {
  attachmentKeys?: InputMaybe<Array<Scalars['string']>>;
  description?: InputMaybe<Scalars['string']>;
  injuriesInvolved?: InputMaybe<Scalars['bool']>;
  insuredName?: InputMaybe<Scalars['string']>;
  insuredVehicles?: InputMaybe<Array<ClaimVehicle_InputObject>>;
  lineOfBusiness: LineOfBusiness;
  lossDate: Scalars['Time'];
  lossLocation?: InputMaybe<Scalars['string']>;
  lossState: Scalars['string'];
  noticeType: FnolNoticeType;
  otherVehicles?: InputMaybe<Array<ClaimVehicle_InputObject>>;
  police?: InputMaybe<Police_InputObject>;
  policyNumber: Scalars['string'];
  reporter: Reporter_InputObject;
};

export type MutationCreateFleetSafetyReportArgs = {
  delegateUserID?: InputMaybe<Scalars['string']>;
  dotNumber: Scalars['string'];
  starred?: InputMaybe<Scalars['bool']>;
};

export type MutationCreateRoleArgs = {
  agencyID?: InputMaybe<Scalars['string']>;
  fleetID?: InputMaybe<Scalars['string']>;
  group: RoleGroupEnum;
  userID: Scalars['string'];
};

export type MutationCreateUserArgs = {
  email: Scalars['string'];
  firstName: Scalars['string'];
  lastName: Scalars['string'];
  password: Scalars['string'];
  phoneNumber?: InputMaybe<Scalars['string']>;
  profilePicture?: InputMaybe<Scalars['string']>;
  roles: Array<CreateUserRoleArgs_InputObject>;
  title?: InputMaybe<Scalars['string']>;
};

export type MutationDeactivateUserArgs = {
  userID: Scalars['string'];
};

export type MutationDeleteRoleArgs = {
  roleID: Scalars['string'];
  userID: Scalars['string'];
};

export type MutationDeleteSentInspectionsArgs = {
  inspectionIDs: Array<Scalars['int64']>;
  reportID: Scalars['string'];
};

export type MutationFinalizeTelematicsArgs = {
  authCode: Scalars['string'];
  error?: InputMaybe<Scalars['string']>;
  scope?: InputMaybe<Scalars['string']>;
  state: Scalars['string'];
  tsp: Tsp;
};

export type MutationInitiateTelematicsArgs = {
  safetyReportID: Scalars['string'];
  tsp: Tsp;
};

export type MutationInviteAgencyUserFromAgentsArgs = {
  agencyID: Scalars['string'];
  email: Scalars['string'];
  role: RoleGroupEnum;
  sFDCAgencyRoles?: InputMaybe<Array<AgencyRole>>;
};

export type MutationInviteAgencyUserFromSupportArgs = {
  agencyID: Scalars['string'];
  email: Scalars['string'];
  role: RoleGroupEnum;
  sFDCAgencyRoles?: InputMaybe<Array<AgencyRole>>;
};

export type MutationInviteFleetUserArgs = {
  email: Scalars['string'];
  fleetSafetyReportID: Scalars['string'];
};

export type MutationPatchAgencyArgs = {
  address?: InputMaybe<Address_InputObject>;
  id: Scalars['string'];
  name?: InputMaybe<Scalars['string']>;
};

export type MutationPatchUserArgs = {
  email?: InputMaybe<Scalars['string']>;
  firstName?: InputMaybe<Scalars['string']>;
  id: Scalars['string'];
  lastName?: InputMaybe<Scalars['string']>;
  oldPassword?: InputMaybe<Scalars['string']>;
  password?: InputMaybe<Scalars['string']>;
  phoneNumber?: InputMaybe<Scalars['string']>;
  profilePicture?: InputMaybe<Scalars['string']>;
  title?: InputMaybe<Scalars['string']>;
};

export type MutationPrintFleetSafetyReportArgs = {
  endTime?: InputMaybe<Scalars['Time']>;
  reportID: Scalars['string'];
  startTime?: InputMaybe<Scalars['Time']>;
};

export type MutationSendClaimSummaryArgs = {
  fleetManagerEmail: Scalars['string'];
  fleetManagerFullName: Scalars['string'];
  policyNumber: Scalars['string'];
  summaries: Array<ClaimSummary_InputObject>;
};

export type MutationSetFleetStarredStatusArgs = {
  markStarred: Scalars['bool'];
  reportId: Scalars['string'];
  userID?: InputMaybe<Scalars['string']>;
};

export type MutationShareSafetyReportArgs = {
  delegateUserID?: InputMaybe<Scalars['string']>;
  id: Scalars['string'];
};

export type MutationUpdateAgentDetailArgs = {
  officeDepartmentRegion: Scalars['string'];
  phoneNumber: Scalars['string'];
  preferredStates: Array<Scalars['string']>;
  sFDCAgencyRoles?: InputMaybe<Array<AgencyRole>>;
  title: Scalars['string'];
  workLocation?: InputMaybe<Scalars['string']>;
};

export type MutationUpdateAgentLicenseArgs = {
  effectiveDate?: InputMaybe<Scalars['Time']>;
  expirationDate?: InputMaybe<Scalars['Time']>;
  id: Scalars['string'];
  licenseNumber?: InputMaybe<Scalars['string']>;
  licenseStatus?: InputMaybe<Scalars['string']>;
  licenseType?: InputMaybe<Scalars['string']>;
  state?: InputMaybe<Scalars['string']>;
};

export type MutationUpdateUserNotificationPreferencesArgs = {
  preferences: Array<UserWorkflowPreference_InputObject>;
};

export type Note = {
  __typename?: 'Note';
  category?: Maybe<Scalars['string']>;
  claimExternalId: Scalars['string'];
  createdAt: Scalars['Time'];
  externalId: Scalars['string'];
  id: Scalars['string'];
  modifiedAt: Scalars['Time'];
  updatedAt: Scalars['Time'];
  value: Scalars['string'];
};

export type OosSummary = {
  __typename?: 'OOSSummary';
  category: Scalars['string'];
  inspections: Scalars['int'];
  nationalAverage: Scalars['float64'];
  oOSPercent: Scalars['float64'];
  oOSViolations: Scalars['int'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor: Scalars['string'];
  hasNextPage: Scalars['bool'];
  hasPrevPage: Scalars['bool'];
  pages: Array<Scalars['string']>;
  startCursor: Scalars['string'];
};

export type Police_InputObject = {
  agencyName?: InputMaybe<Scalars['string']>;
  reportNumber?: InputMaybe<Scalars['string']>;
};

export type Policy = {
  __typename?: 'Policy';
  coverages: Array<PolicyCoverageEnums>;
  endDate: Scalars['Time'];
  id: Scalars['string'];
  insuredDOTNumber: Scalars['string'];
  insuredName: Scalars['string'];
  policyNumber: Scalars['string'];
  startDate: Scalars['Time'];
  state: PolicyState;
};

export enum PolicyCoverageEnums {
  /** CoverageAutoLiability */
  CoverageAutoLiability = 'CoverageAutoLiability',
  /** CoverageAutoPhysicalDamage */
  CoverageAutoPhysicalDamage = 'CoverageAutoPhysicalDamage',
  /** CoverageGeneralLiability */
  CoverageGeneralLiability = 'CoverageGeneralLiability',
  /** CoverageMotorTruckCargo */
  CoverageMotorTruckCargo = 'CoverageMotorTruckCargo',
}

export enum PolicyState {
  /** Active */
  Active = 'Active',
  /** CancellationFiled */
  CancellationFiled = 'CancellationFiled',
  /** Cancelled */
  Cancelled = 'Cancelled',
  /** Created */
  Created = 'Created',
  /** Expired */
  Expired = 'Expired',
  /** Generated */
  Generated = 'Generated',
  /** Invalid */
  Invalid = 'Invalid',
  /** Stale */
  Stale = 'Stale',
}

export type PresignedUploadLink = {
  __typename?: 'PresignedUploadLink';
  key: Scalars['string'];
  url: Scalars['string'];
};

export type PrintFleetSafetyReportLink = {
  __typename?: 'PrintFleetSafetyReportLink';
  id: Scalars['string'];
  url: Scalars['string'];
};

export type Properties = {
  __typename?: 'Properties';
  countyCode?: Maybe<Scalars['string']>;
  inspectionRecords: Array<Inspection>;
  inspection_count?: Maybe<Scalars['int64']>;
  inspection_percentage?: Maybe<Scalars['float64']>;
  name?: Maybe<Scalars['string']>;
  violation_count?: Maybe<Scalars['int64']>;
  violation_percentage?: Maybe<Scalars['float64']>;
};

export type Query = {
  __typename?: 'Query';
  agencies: Array<Agency>;
  agency?: Maybe<Agency>;
  cameraEvents: Array<CameraEvents>;
  claims: Array<Claims>;
  claimsByDOTNumber: Array<Claims>;
  claimsPresignedUploadLinks: Array<PresignedUploadLink>;
  draftFnols: Array<DraftFnol>;
  fetchFleetSafetySearch: Array<FleetSearchResult>;
  fleet?: Maybe<Fleet>;
  fleetSafetyReport?: Maybe<FleetSafetyReport>;
  fleetSafetyReports: FleetSafetyReportConnection;
  fnols: Array<Fnol>;
  initiateDriverViolationFetch?: Maybe<InitiateDriverViolationFetchResults>;
  policies: Array<Policy>;
  user?: Maybe<User>;
  userNotificationPreferences: Array<UserWorkflowPreference>;
  users: Array<User>;
};

export type QueryAgencyArgs = {
  id: Scalars['string'];
};

export type QueryCameraEventsArgs = {
  endTime: Scalars['Time'];
  policyNumber: Scalars['string'];
  startTime: Scalars['Time'];
};

export type QueryClaimsByDotNumberArgs = {
  dotNumber: Scalars['string'];
};

export type QueryClaimsPresignedUploadLinksArgs = {
  fileNames: Array<Scalars['string']>;
  policyNumber: Scalars['string'];
};

export type QueryDraftFnolsArgs = {
  hasFNOL?: InputMaybe<Scalars['bool']>;
};

export type QueryFetchFleetSafetySearchArgs = {
  searchText?: InputMaybe<Scalars['string']>;
};

export type QueryFleetArgs = {
  dotNumber?: InputMaybe<Scalars['string']>;
  id?: InputMaybe<Scalars['string']>;
};

export type QueryFleetSafetyReportArgs = {
  id: Scalars['string'];
};

export type QueryFleetSafetyReportsArgs = {
  after?: InputMaybe<Scalars['string']>;
  before?: InputMaybe<Scalars['string']>;
  filterText?: InputMaybe<Scalars['string']>;
  filterTextFields?: InputMaybe<Array<Scalars['string']>>;
  filterType?: InputMaybe<Scalars['string']>;
  first?: InputMaybe<Scalars['int64']>;
  last?: InputMaybe<Scalars['int64']>;
  sortBy?: InputMaybe<Scalars['string']>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type QueryInitiateDriverViolationFetchArgs = {
  password: Scalars['string'];
  reportId: Scalars['string'];
  username: Scalars['string'];
};

export type QueryPoliciesArgs = {
  activeDateIn?: InputMaybe<Scalars['Time']>;
  agencyId?: InputMaybe<Scalars['string']>;
  dotNumber?: InputMaybe<Scalars['string']>;
  hasClaims?: InputMaybe<Scalars['bool']>;
  policyStates?: InputMaybe<Array<PolicyState>>;
};

export type QueryUserArgs = {
  id?: InputMaybe<Scalars['string']>;
};

export type Reason = {
  __typename?: 'Reason';
  basicCategory?: Maybe<Scalars['string']>;
  link?: Maybe<Scalars['string']>;
  vINs: Array<Scalars['string']>;
  violationCount?: Maybe<Scalars['int']>;
  violationGroup?: Maybe<Scalars['string']>;
  weightage?: Maybe<Scalars['float64']>;
};

export type RecommendationConnection = {
  __typename?: 'RecommendationConnection';
  edges: Array<RecommendationEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['int64'];
};

export type RecommendationEdge = {
  __typename?: 'RecommendationEdge';
  cursor: Scalars['string'];
  node: Recommendation;
};

export enum RecommendationImpact {
  /** High */
  High = 'High',
  /** Low */
  Low = 'Low',
  /** Medium */
  Medium = 'Medium',
}

export type RecommendationSection = {
  __typename?: 'RecommendationSection';
  chart?: Maybe<Chart>;
  markdown: Scalars['string'];
  reasons: Array<Reason>;
};

export type Reporter_InputObject = {
  email?: InputMaybe<Scalars['string']>;
  firstName?: InputMaybe<Scalars['string']>;
  lastName?: InputMaybe<Scalars['string']>;
  phone?: InputMaybe<Scalars['string']>;
};

export enum RoleGroupEnum {
  /** AgencyAccountManagerRole */
  AgencyAccountManagerRole = 'AgencyAccountManagerRole',
  /** AgencyAdminReaderRole */
  AgencyAdminReaderRole = 'AgencyAdminReaderRole',
  /** AgencyAdminRole */
  AgencyAdminRole = 'AgencyAdminRole',
  /** AgencyProducerRole */
  AgencyProducerRole = 'AgencyProducerRole',
  /** AgencyServiceMemberRole */
  AgencyServiceMemberRole = 'AgencyServiceMemberRole',
  /** BillingAdminRole */
  BillingAdminRole = 'BillingAdminRole',
  /** CanopiusUnderwriterRole */
  CanopiusUnderwriterRole = 'CanopiusUnderwriterRole',
  /** ClaimsAdminRole */
  ClaimsAdminRole = 'ClaimsAdminRole',
  /** FleetAdminRole */
  FleetAdminRole = 'FleetAdminRole',
  /** FleetBDRole */
  FleetBdRole = 'FleetBDRole',
  /** FleetReaderRole */
  FleetReaderRole = 'FleetReaderRole',
  /** Level1UnderwriterRole */
  Level1UnderwriterRole = 'Level1UnderwriterRole',
  /** Level2UnderwriterRole */
  Level2UnderwriterRole = 'Level2UnderwriterRole',
  /** Level3UnderwriterRole */
  Level3UnderwriterRole = 'Level3UnderwriterRole',
  /** Level4UnderwriterRole */
  Level4UnderwriterRole = 'Level4UnderwriterRole',
  /** Level5UnderwriterRole */
  Level5UnderwriterRole = 'Level5UnderwriterRole',
  /** Level6UnderwriterRole */
  Level6UnderwriterRole = 'Level6UnderwriterRole',
  /** Level7UnderwriterRole */
  Level7UnderwriterRole = 'Level7UnderwriterRole',
  /** NirvanaAPIUserRole */
  NirvanaApiUserRole = 'NirvanaAPIUserRole',
  /** NonFleetBDRole */
  NonFleetBdRole = 'NonFleetBDRole',
  /** PolicyAdminReaderRole */
  PolicyAdminReaderRole = 'PolicyAdminReaderRole',
  /** PowerUserRole */
  PowerUserRole = 'PowerUserRole',
  /** QAUnderwriterRole */
  QaUnderwriterRole = 'QAUnderwriterRole',
  /** SafetyConsultantReader */
  SafetyConsultantReader = 'SafetyConsultantReader',
  /** SeniorSupportRole */
  SeniorSupportRole = 'SeniorSupportRole',
  /** SeniorUnderwriterRole */
  SeniorUnderwriterRole = 'SeniorUnderwriterRole',
  /** SharedFleetTelematicsRole */
  SharedFleetTelematicsRole = 'SharedFleetTelematicsRole',
  /** SharedSafetyReaderRole */
  SharedSafetyReaderRole = 'SharedSafetyReaderRole',
  /** SuperuserRole */
  SuperuserRole = 'SuperuserRole',
  /** UnderwriterManagerRole */
  UnderwriterManagerRole = 'UnderwriterManagerRole',
  /** UserOwnerRole */
  UserOwnerRole = 'UserOwnerRole',
}

export type SafetyReportShare = {
  __typename?: 'SafetyReportShare';
  expiresAt?: Maybe<Scalars['Time']>;
  id: Scalars['string'];
};

export type ScoreTrendItem = {
  __typename?: 'ScoreTrendItem';
  isConfidentScore: Scalars['bool'];
  lowConfidenceReason?: Maybe<Scalars['int']>;
  score: Scalars['float64'];
  timestamp: Scalars['Time'];
};

export type ShipperData = {
  __typename?: 'ShipperData';
  inspectionsCount?: Maybe<Scalars['int']>;
  shipperName?: Maybe<Scalars['string']>;
};

export enum SortOrder {
  /** 0 */
  Asc = 'asc',
  /** 1 */
  Desc = 'desc',
}

export enum Status {
  /** Created */
  Created = 'Created',
  /** Invalid */
  Invalid = 'Invalid',
  /** Undefined */
  Undefined = 'Undefined',
  /** Valid */
  Valid = 'Valid',
  /** Validating */
  Validating = 'Validating',
}

export enum Tsp {
  /** TSP2BROELD */
  Tsp2Broeld = 'TSP2BROELD',
  /** TSP3MD */
  Tsp3Md = 'TSP3MD',
  /** TSP888ELD */
  Tsp888Eld = 'TSP888ELD',
  /** TSPARIFleet */
  TspariFleet = 'TSPARIFleet',
  /** TSPATAndTFleet */
  TspatAndTFleet = 'TSPATAndTFleet',
  /** TSPATAndTFleetComplete */
  TspatAndTFleetComplete = 'TSPATAndTFleetComplete',
  /** TSPActionELD */
  TspActionEld = 'TSPActionELD',
  /** TSPActsoftEncore */
  TspActsoftEncore = 'TSPActsoftEncore',
  /** TSPAdvantageAssetTracking */
  TspAdvantageAssetTracking = 'TSPAdvantageAssetTracking',
  /** TSPAdvantageOne */
  TspAdvantageOne = 'TSPAdvantageOne',
  /** TSPAgilisLinxup */
  TspAgilisLinxup = 'TSPAgilisLinxup',
  /** TSPAlfaELD */
  TspAlfaEld = 'TSPAlfaELD',
  /** TSPApolloELD */
  TspApolloEld = 'TSPApolloELD',
  /** TSPArgosConnectedSolutions */
  TspArgosConnectedSolutions = 'TSPArgosConnectedSolutions',
  /** TSPAssuredTelematics */
  TspAssuredTelematics = 'TSPAssuredTelematics',
  /** TSPAttriX */
  TspAttriX = 'TSPAttriX',
  /** TSPAwareGPS */
  TspAwareGps = 'TSPAwareGPS',
  /** TSPAzuga */
  TspAzuga = 'TSPAzuga',
  /** TSPBELLFAMELD */
  Tspbellfameld = 'TSPBELLFAMELD',
  /** TSPBadgerFleetSolutions */
  TspBadgerFleetSolutions = 'TSPBadgerFleetSolutions',
  /** TSPBigRoad */
  TspBigRoad = 'TSPBigRoad',
  /** TSPBlackBearELD */
  TspBlackBearEld = 'TSPBlackBearELD',
  /** TSPBlueArrow */
  TspBlueArrow = 'TSPBlueArrow',
  /** TSPBlueArrowTelematics */
  TspBlueArrowTelematics = 'TSPBlueArrowTelematics',
  /** TSPBlueInkTechnology */
  TspBlueInkTechnology = 'TSPBlueInkTechnology',
  /** TSPBlueStarELD */
  TspBlueStarEld = 'TSPBlueStarELD',
  /** TSPCNELD */
  Tspcneld = 'TSPCNELD',
  /** TSPCTELogELD */
  TspcteLogEld = 'TSPCTELogELD',
  /** TSPCarrierHQ */
  TspCarrierHq = 'TSPCarrierHQ',
  /** TSPClutchELD */
  TspClutchEld = 'TSPClutchELD',
  /** TSPColumbusELD */
  TspColumbusEld = 'TSPColumbusELD',
  /** TSPCommandGPS */
  TspCommandGps = 'TSPCommandGPS',
  /** TSPContiGO */
  TspContiGo = 'TSPContiGO',
  /** TSPCoretex */
  TspCoretex = 'TSPCoretex',
  /** TSPCyntrXELDPlus */
  TspCyntrXeldPlus = 'TSPCyntrXELDPlus',
  /** TSPDSGELOGS */
  Tspdsgelogs = 'TSPDSGELOGS',
  /** TSPDailyELD */
  TspDailyEld = 'TSPDailyELD',
  /** TSPDigitalELD */
  TspDigitalEld = 'TSPDigitalELD',
  /** TSPDreamELD */
  TspDreamEld = 'TSPDreamELD',
  /** TSPDriveEDR */
  TspDriveEdr = 'TSPDriveEDR',
  /** TSPELDFleet */
  TspeldFleet = 'TSPELDFleet',
  /** TSPELDMandate */
  TspeldMandate = 'TSPELDMandate',
  /** TSPELDMandatePlus */
  TspeldMandatePlus = 'TSPELDMandatePlus',
  /** TSPELDMandatePro */
  TspeldMandatePro = 'TSPELDMandatePro',
  /** TSPELDOne */
  TspeldOne = 'TSPELDOne',
  /** TSPELDRider */
  TspeldRider = 'TSPELDRider',
  /** TSPELDTab */
  TspeldTab = 'TSPELDTab',
  /** TSPELOG42 */
  Tspelog42 = 'TSPELOG42',
  /** TSPEROAD */
  Tsperoad = 'TSPEROAD',
  /** TSPEVOELD */
  Tspevoeld = 'TSPEVOELD',
  /** TSPEZELDSolutions */
  TspezeldSolutions = 'TSPEZELDSolutions',
  /** TSPEZFleet */
  TspezFleet = 'TSPEZFleet',
  /** TSPEZLogz */
  TspezLogz = 'TSPEZLogz',
  /** TSPEagleWireless */
  TspEagleWireless = 'TSPEagleWireless',
  /** TSPElevenELD */
  TspElevenEld = 'TSPElevenELD',
  /** TSPEnVueTelematics */
  TspEnVueTelematics = 'TSPEnVueTelematics',
  /** TSPExpressWayELD */
  TspExpressWayEld = 'TSPExpressWayELD',
  /** TSPFACTORELD */
  Tspfactoreld = 'TSPFACTORELD',
  /** TSPFMELD */
  Tspfmeld = 'TSPFMELD',
  /** TSPFleetBossGPS */
  TspFleetBossGps = 'TSPFleetBossGPS',
  /** TSPFleetComplete */
  TspFleetComplete = 'TSPFleetComplete',
  /** TSPFleetLocate21 */
  TspFleetLocate21 = 'TSPFleetLocate21',
  /** TSPFleetLocateAdvancedAndCompliance */
  TspFleetLocateAdvancedAndCompliance = 'TSPFleetLocateAdvancedAndCompliance',
  /** TSPFleetLocateELD */
  TspFleetLocateEld = 'TSPFleetLocateELD',
  /** TSPFleetNavSystems */
  TspFleetNavSystems = 'TSPFleetNavSystems',
  /** TSPFleetProfitCenter */
  TspFleetProfitCenter = 'TSPFleetProfitCenter',
  /** TSPFleetSharp */
  TspFleetSharp = 'TSPFleetSharp',
  /** TSPFleetistics */
  TspFleetistics = 'TSPFleetistics',
  /** TSPFleetmaster */
  TspFleetmaster = 'TSPFleetmaster',
  /** TSPFleetmatics */
  TspFleetmatics = 'TSPFleetmatics',
  /** TSPFlexport */
  TspFlexport = 'TSPFlexport',
  /** TSPForceByMojio */
  TspForceByMojio = 'TSPForceByMojio',
  /** TSPForwardThinkingELD */
  TspForwardThinkingEld = 'TSPForwardThinkingELD',
  /** TSPGPSCommander */
  TspgpsCommander = 'TSPGPSCommander',
  /** TSPGPSFleetFinder */
  TspgpsFleetFinder = 'TSPGPSFleetFinder',
  /** TSPGPSInsight */
  TspgpsInsight = 'TSPGPSInsight',
  /** TSPGPSSolutions */
  TspgpsSolutions = 'TSPGPSSolutions',
  /** TSPGPSTab */
  TspgpsTab = 'TSPGPSTab',
  /** TSPGPSTrackingCanada */
  TspgpsTrackingCanada = 'TSPGPSTrackingCanada',
  /** TSPGPSTrackit */
  TspgpsTrackit = 'TSPGPSTrackit',
  /** TSPGarmin */
  TspGarmin = 'TSPGarmin',
  /** TSPGeoforce */
  TspGeoforce = 'TSPGeoforce',
  /** TSPGeotab */
  TspGeotab = 'TSPGeotab',
  /** TSPGlobalELD */
  TspGlobalEld = 'TSPGlobalELD',
  /** TSPGlostone */
  TspGlostone = 'TSPGlostone',
  /** TSPGoFleet */
  TspGoFleet = 'TSPGoFleet',
  /** TSPGoGPS */
  TspGoGps = 'TSPGoGPS',
  /** TSPGoodDealGPS */
  TspGoodDealGps = 'TSPGoodDealGPS',
  /** TSPGorillaSafety */
  TspGorillaSafety = 'TSPGorillaSafety',
  /** TSPGrayboxSolutions */
  TspGrayboxSolutions = 'TSPGrayboxSolutions',
  /** TSPGridline */
  TspGridline = 'TSPGridline',
  /** TSPHOS247 */
  Tsphos247 = 'TSPHOS247',
  /** TSPHighPointGPS */
  TspHighPointGps = 'TSPHighPointGPS',
  /** TSPHorizonPathELD */
  TspHorizonPathEld = 'TSPHorizonPathELD',
  /** TSPHutchSystems */
  TspHutchSystems = 'TSPHutchSystems',
  /** TSPIDELD */
  Tspideld = 'TSPIDELD',
  /** TSPISAACInstruments */
  TspisaacInstruments = 'TSPISAACInstruments',
  /** TSPInTouchGPS */
  TspInTouchGps = 'TSPInTouchGPS',
  /** TSPInsightMobileData */
  TspInsightMobileData = 'TSPInsightMobileData',
  /** TSPIntellishift */
  TspIntellishift = 'TSPIntellishift',
  /** TSPIntouchELD */
  TspIntouchEld = 'TSPIntouchELD',
  /** TSPIoTab */
  TspIoTab = 'TSPIoTab',
  /** TSPJJKeller */
  TspjjKeller = 'TSPJJKeller',
  /** TSPKSKELD */
  Tspkskeld = 'TSPKSKELD',
  /** TSPKeepTruckin */
  TspKeepTruckin = 'TSPKeepTruckin',
  /** TSPKeepTruckinSG */
  TspKeepTruckinSg = 'TSPKeepTruckinSG',
  /** TSPKeepTruckinSafety */
  TspKeepTruckinSafety = 'TSPKeepTruckinSafety',
  /** TSPKonexial */
  TspKonexial = 'TSPKonexial',
  /** TSPLBTechnology */
  TsplbTechnology = 'TSPLBTechnology',
  /** TSPLEGACYELD */
  Tsplegacyeld = 'TSPLEGACYELD',
  /** TSPLogPlusELD */
  TspLogPlusEld = 'TSPLogPlusELD',
  /** TSPLookTruckELD */
  TspLookTruckEld = 'TSPLookTruckELD',
  /** TSPLynx */
  TspLynx = 'TSPLynx',
  /** TSPLytXDriveCam */
  TspLytXDriveCam = 'TSPLytXDriveCam',
  /** TSPMOONLIGHTELD */
  Tspmoonlighteld = 'TSPMOONLIGHTELD',
  /** TSPMTELD */
  Tspmteld = 'TSPMTELD',
  /** TSPMasterELD */
  TspMasterEld = 'TSPMasterELD',
  /** TSPMatrack */
  TspMatrack = 'TSPMatrack',
  /** TSPMock */
  TspMock = 'TSPMock',
  /** TSPMonarchGPS */
  TspMonarchGps = 'TSPMonarchGPS',
  /** TSPMondo */
  TspMondo = 'TSPMondo',
  /** TSPMountainELD */
  TspMountainEld = 'TSPMountainELD',
  /** TSPMy20ELD */
  TspMy20Eld = 'TSPMy20ELD',
  /** TSPNetradyneInc */
  TspNetradyneInc = 'TSPNetradyneInc',
  /** TSPNewELDWorld */
  TspNewEldWorld = 'TSPNewELDWorld',
  /** TSPNextraq */
  TspNextraq = 'TSPNextraq',
  /** TSPNexusELD */
  TspNexusEld = 'TSPNexusELD',
  /** TSPNotConnected */
  TspNotConnected = 'TSPNotConnected',
  /** TSPOaneELD */
  TspOaneEld = 'TSPOaneELD',
  /** TSPOmnitracs */
  TspOmnitracs = 'TSPOmnitracs',
  /** TSPOmnitracsES */
  TspOmnitracsEs = 'TSPOmnitracsES',
  /** TSPOmnitracsXRS */
  TspOmnitracsXrs = 'TSPOmnitracsXRS',
  /** TSPOnTrakSolutions */
  TspOnTrakSolutions = 'TSPOnTrakSolutions',
  /** TSPOnePlusELD */
  TspOnePlusEld = 'TSPOnePlusELD',
  /** TSPOneStepGPS */
  TspOneStepGps = 'TSPOneStepGPS',
  /** TSPOntimeELD */
  TspOntimeEld = 'TSPOntimeELD',
  /** TSPOptimaELD */
  TspOptimaEld = 'TSPOptimaELD',
  /** TSPOrbcomm */
  TspOrbcomm = 'TSPOrbcomm',
  /** TSPOrion */
  TspOrion = 'TSPOrion',
  /** TSPOther */
  TspOther = 'TSPOther',
  /** TSPPeopleNet */
  TspPeopleNet = 'TSPPeopleNet',
  /** TSPPhoenixELD */
  TspPhoenixEld = 'TSPPhoenixELD',
  /** TSPPlatformScience */
  TspPlatformScience = 'TSPPlatformScience',
  /** TSPPositrace */
  TspPositrace = 'TSPPositrace',
  /** TSPPowerELD */
  TspPowerEld = 'TSPPowerELD',
  /** TSPPowerFleet */
  TspPowerFleet = 'TSPPowerFleet',
  /** TSPPrePassELD */
  TspPrePassEld = 'TSPPrePassELD',
  /** TSPProLogs */
  TspProLogs = 'TSPProLogs',
  /** TSPProRideELD */
  TspProRideEld = 'TSPProRideELD',
  /** TSPQualityGPS */
  TspQualityGps = 'TSPQualityGPS',
  /** TSPRMJTechnologies */
  TsprmjTechnologies = 'TSPRMJTechnologies',
  /** TSPRandMcNally */
  TspRandMcNally = 'TSPRandMcNally',
  /** TSPRealELD */
  TspRealEld = 'TSPRealELD',
  /** TSPReliableELD */
  TspReliableEld = 'TSPReliableELD',
  /** TSPRenaissanceELD */
  TspRenaissanceEld = 'TSPRenaissanceELD',
  /** TSPRigbot */
  TspRigbot = 'TSPRigbot',
  /** TSPRightTruckingELD */
  TspRightTruckingEld = 'TSPRightTruckingELD',
  /** TSPRoadReadySolutions */
  TspRoadReadySolutions = 'TSPRoadReadySolutions',
  /** TSPRoadStarELD */
  TspRoadStarEld = 'TSPRoadStarELD',
  /** TSPRouteELD */
  TspRouteEld = 'TSPRouteELD',
  /** TSPRushEnterpises */
  TspRushEnterpises = 'TSPRushEnterpises',
  /** TSPSFELD */
  Tspsfeld = 'TSPSFELD',
  /** TSPSMARTCHOICELOGSELD */
  Tspsmartchoicelogseld = 'TSPSMARTCHOICELOGSELD',
  /** TSPSRELD */
  Tspsreld = 'TSPSRELD',
  /** TSPSTATEELOGS */
  Tspstateelogs = 'TSPSTATEELOGS',
  /** TSPSafetyComplianceSolutions */
  TspSafetyComplianceSolutions = 'TSPSafetyComplianceSolutions',
  /** TSPSafetyVision */
  TspSafetyVision = 'TSPSafetyVision',
  /** TSPSamsara */
  TspSamsara = 'TSPSamsara',
  /** TSPSamsaraSG */
  TspSamsaraSg = 'TSPSamsaraSG',
  /** TSPSamsaraSafety */
  TspSamsaraSafety = 'TSPSamsaraSafety',
  /** TSPSimpleELOG */
  TspSimpleElog = 'TSPSimpleELOG',
  /** TSPSimpleTruckELD */
  TspSimpleTruckEld = 'TSPSimpleTruckELD',
  /** TSPSmartDrive */
  TspSmartDrive = 'TSPSmartDrive',
  /** TSPSmartWitness */
  TspSmartWitness = 'TSPSmartWitness',
  /** TSPSpeedELD */
  TspSpeedEld = 'TSPSpeedELD',
  /** TSPSpireonFleetLocate */
  TspSpireonFleetLocate = 'TSPSpireonFleetLocate',
  /** TSPStreetEagle */
  TspStreetEagle = 'TSPStreetEagle',
  /** TSPSwiftELD */
  TspSwiftEld = 'TSPSwiftELD',
  /** TSPTFMELD */
  Tsptfmeld = 'TSPTFMELD',
  /** TSPTMELD */
  Tsptmeld = 'TSPTMELD',
  /** TSPTMobile */
  TsptMobile = 'TSPTMobile',
  /** TSPTRCeLOGS */
  TsptrCeLogs = 'TSPTRCeLOGS',
  /** TSPTRUSTELD */
  Tsptrusteld = 'TSPTRUSTELD',
  /** TSPTTELD */
  Tsptteld = 'TSPTTELD',
  /** TSPTangerine */
  TspTangerine = 'TSPTangerine',
  /** TSPTeletracNavman */
  TspTeletracNavman = 'TSPTeletracNavman',
  /** TSPTrackEnsureInc */
  TspTrackEnsureInc = 'TSPTrackEnsureInc',
  /** TSPTrackOnHOS */
  TspTrackOnHos = 'TSPTrackOnHOS',
  /** TSPTransflo */
  TspTransflo = 'TSPTransflo',
  /** TSPTraxxisGPS */
  TspTraxxisGps = 'TSPTraxxisGPS',
  /** TSPTrendyELD */
  TspTrendyEld = 'TSPTrendyELD',
  /** TSPTrimble */
  TspTrimble = 'TSPTrimble',
  /** TSPTruPathSystems */
  TspTruPathSystems = 'TSPTruPathSystems',
  /** TSPTruckXELD */
  TspTruckXeld = 'TSPTruckXELD',
  /** TSPTruckerPathELDPro */
  TspTruckerPathEldPro = 'TSPTruckerPathELDPro',
  /** TSPTrueRoadELD */
  TspTrueRoadEld = 'TSPTrueRoadELD',
  /** TSPUnityELD */
  TspUnityEld = 'TSPUnityELD',
  /** TSPVLogELD */
  TspvLogEld = 'TSPVLogELD',
  /** TSPVTS */
  Tspvts = 'TSPVTS',
  /** TSPVerizonConnect */
  TspVerizonConnect = 'TSPVerizonConnect',
  /** TSPVerizonConnectFleet */
  TspVerizonConnectFleet = 'TSPVerizonConnectFleet',
  /** TSPVerizonConnectReveal */
  TspVerizonConnectReveal = 'TSPVerizonConnectReveal',
  /** TSPVertrax */
  TspVertrax = 'TSPVertrax',
  /** TSPVistaELD */
  TspVistaEld = 'TSPVistaELD',
  /** TSPWebfleet */
  TspWebfleet = 'TSPWebfleet',
  /** TSPWorldTruckingELD */
  TspWorldTruckingEld = 'TSPWorldTruckingELD',
  /** TSPXELD */
  Tspxeld = 'TSPXELD',
  /** TSPZELD */
  Tspzeld = 'TSPZELD',
  /** TSPZenduit */
  TspZenduit = 'TSPZenduit',
  /** TSPZippyELD */
  TspZippyEld = 'TSPZippyELD',
  /** TSPZonar */
  TspZonar = 'TSPZonar',
  /** TSPeasiTrack */
  TsPeasiTrack = 'TSPeasiTrack',
}

export type Table = {
  __typename?: 'Table';
  records: Array<Array<Scalars['string']>>;
};

export type TelematicsVehicle = {
  __typename?: 'TelematicsVehicle';
  handleID: Scalars['string'];
  mileage?: Maybe<VehicleMileage>;
  riskScores: Array<VehicleScoreTrendItem>;
  tspID: Scalars['string'];
  vin: Scalars['string'];
};

export type UserWorkflowPreference = {
  __typename?: 'UserWorkflowPreference';
  email: Scalars['bool'];
  sms: Scalars['bool'];
  workflow: Scalars['string'];
};

export type UserWorkflowPreference_InputObject = {
  email: Scalars['bool'];
  sms: Scalars['bool'];
  workflow: Scalars['string'];
};

export type VehicleMileage = {
  __typename?: 'VehicleMileage';
  distanceMiles: Scalars['float64'];
};

export type VehicleStat = {
  __typename?: 'VehicleStat';
  keyBehaviour?: Maybe<Scalars['string']>;
  riskScore?: Maybe<Scalars['float64']>;
  riskScoreErrorMessages?: Maybe<Scalars['string']>;
  vIN?: Maybe<Scalars['string']>;
  vehicleName?: Maybe<Scalars['string']>;
  vehicleTSPName?: Maybe<Scalars['string']>;
  violationCount?: Maybe<Scalars['int']>;
  violationSeverityTimeWeighted?: Maybe<Scalars['float64']>;
  violationSeverityWeight?: Maybe<Scalars['float64']>;
};

export type VehiclesStatsList = {
  __typename?: 'VehiclesStatsList';
  vehicles: Array<VehicleStat>;
};

export type ViolationStat = {
  __typename?: 'ViolationStat';
  bASICCategory?: Maybe<Scalars['string']>;
  code?: Maybe<Scalars['string']>;
  description?: Maybe<Scalars['string']>;
  group?: Maybe<Scalars['string']>;
  inspectionDate?: Maybe<Scalars['Time']>;
  location?: Maybe<Location>;
  severityWeight?: Maybe<Scalars['int']>;
  vINs: Array<Scalars['string']>;
};

export type ViolationsStatsList = {
  __typename?: 'ViolationsStatsList';
  violations: Array<ViolationStat>;
};

export type ActivateUserResponse = {
  __typename?: 'activateUserResponse';
  expiration: Scalars['Time'];
  fleetSafetyReportId?: Maybe<Scalars['string']>;
  sessionId: Scalars['string'];
};

export type Agency = {
  __typename?: 'agency';
  address?: Maybe<Address>;
  createdAt: Scalars['Time'];
  id: Scalars['string'];
  isTestAgency: Scalars['bool'];
  name: Scalars['string'];
  updatedAt?: Maybe<Scalars['Time']>;
};

export type AgencyUserInvite = {
  __typename?: 'agencyUserInvite';
  shareID: Scalars['string'];
};

export type BasicScore = {
  __typename?: 'basicScore';
  discount?: Maybe<Scalars['float64']>;
  scoreDate: Scalars['string'];
  scores: Array<BasicScoreDetail>;
};

export type CameraEvents = {
  __typename?: 'cameraEvents';
  coachingState: Scalars['string'];
  forwardVideoURL?: Maybe<Scalars['string']>;
  id: Scalars['string'];
  inwardVideoURL?: Maybe<Scalars['string']>;
  labels: Array<Scalars['string']>;
  time: Scalars['Time'];
  trackedInwardVideoURL?: Maybe<Scalars['string']>;
  vin: Scalars['string'];
};

export type ClaimSummary_InputObject = {
  number: Scalars['string'];
  summary: Scalars['string'];
};

export type Claims = {
  __typename?: 'claims';
  adjusterEmail: Scalars['string'];
  adjusterName: Scalars['string'];
  canSubmitFeedback: Scalars['bool'];
  externalId: Scalars['string'];
  feedbacks: Array<ClaimFeedback>;
  id: Scalars['string'];
  insuredDOTNumber: Scalars['string'];
  insuredName: Scalars['string'];
  lineOfBusiness: Scalars['string'];
  modifiedAt: Scalars['Time'];
  notes: Array<Note>;
  policyNumber: Scalars['string'];
  reportedAt: Scalars['Time'];
  reportedBy?: Maybe<Scalars['string']>;
  status: ClaimStatus;
};

export type CreateUserRoleArgs_InputObject = {
  agencyID?: InputMaybe<Scalars['string']>;
  fleetID?: InputMaybe<Scalars['string']>;
  group: RoleGroupEnum;
};

export type DatagovInspection = {
  __typename?: 'datagovInspection';
  alcoholControlSub?: Maybe<Scalars['bool']>;
  changeDate: Scalars['Time'];
  countyCode: Scalars['string'];
  countyCodeState?: Maybe<Scalars['string']>;
  countyName: Scalars['string'];
  createdAt: Scalars['Time'];
  deletedAt?: Maybe<Scalars['Time']>;
  docketNumber?: Maybe<Scalars['int64']>;
  dotNumber: Scalars['int64'];
  driverOOSTotal: Scalars['int'];
  driverViolTotal: Scalars['int'];
  drugIntrdctnArrests: Scalars['int'];
  drugIntrdctnSearch?: Maybe<Scalars['bool']>;
  grossCompVehicleWeight?: Maybe<Scalars['int']>;
  hazmatOOSTotal: Scalars['int'];
  hazmatViolTotal: Scalars['int'];
  id: Scalars['int64'];
  inspEndTime: Scalars['string'];
  inspFacility: Scalars['string'];
  inspInterstate?: Maybe<Scalars['bool']>;
  inspLevelID: Scalars['int'];
  inspStartTime: Scalars['string'];
  inspectionDate: Scalars['Time'];
  localEnfJurisdiction?: Maybe<Scalars['bool']>;
  location?: Maybe<Scalars['string']>;
  locationDesc?: Maybe<Scalars['string']>;
  mcmisAddDate?: Maybe<Scalars['Time']>;
  oosTotal: Scalars['int'];
  postAccInd?: Maybe<Scalars['bool']>;
  region: Scalars['string'];
  reportNumber: Scalars['string'];
  reportState: Scalars['string'];
  shipperName?: Maybe<Scalars['string']>;
  shippingPaperNumber?: Maybe<Scalars['string']>;
  sizeWeightEnf?: Maybe<Scalars['bool']>;
  statusCode: Scalars['string'];
  telematicsAssignments: Array<TelematicsAssignment>;
  trafficEnf?: Maybe<Scalars['bool']>;
  vehicleOOSTotal: Scalars['int'];
  vehicleViolTotal: Scalars['int'];
  vehicles: Array<DatagovVehicle>;
  violTotal: Scalars['int'];
  violations: Array<DatagovViolation>;
};

export type DatagovVehicle = {
  __typename?: 'datagovVehicle';
  changeDate: Scalars['Time'];
  company?: Maybe<Scalars['string']>;
  createdAt: Scalars['Time'];
  decalIssued?: Maybe<Scalars['bool']>;
  decalNumber?: Maybe<Scalars['string']>;
  deletedAt?: Maybe<Scalars['Time']>;
  id: Scalars['int64'];
  inspectionID: Scalars['int64'];
  licensePlate?: Maybe<Scalars['string']>;
  licenseState?: Maybe<Scalars['string']>;
  make?: Maybe<Scalars['string']>;
  sequenceNumber: Scalars['int'];
  vehicleTypeID: Scalars['int'];
  vin?: Maybe<Scalars['string']>;
};

export type DatagovViolation = {
  __typename?: 'datagovViolation';
  changeDate: Scalars['Time'];
  citationNumber?: Maybe<Scalars['string']>;
  code?: Maybe<Scalars['string']>;
  createdAt: Scalars['Time'];
  defectVerificationID?: Maybe<Scalars['int']>;
  deletedAt?: Maybe<Scalars['Time']>;
  description?: Maybe<Scalars['string']>;
  group?: Maybe<ViolationGroup>;
  id: Scalars['int64'];
  inspViolUnit: Scalars['string'];
  inspViolationCategoryID: Scalars['int'];
  inspectionID: Scalars['int64'];
  oos?: Maybe<Scalars['bool']>;
  partNumber: Scalars['string'];
  partNumberSection: Scalars['string'];
  seqNumber: Scalars['int'];
  severity?: Maybe<Scalars['int']>;
  vehicle?: Maybe<DatagovVehicle>;
};

export type Driver = {
  __typename?: 'driver';
  id: Scalars['string'];
  licenseNumber: Scalars['string'];
  licenseState: Scalars['string'];
  name: Scalars['string'];
};

export type FinalizeTelematicsResponse = {
  __typename?: 'finalizeTelematicsResponse';
  error?: Maybe<Scalars['string']>;
  handleID: Scalars['string'];
  safetyReportID: Scalars['string'];
};

export type Flag = {
  __typename?: 'flag';
  category: FlagCategory;
  chart?: Maybe<Chart>;
  description: Scalars['string'];
  id: Scalars['string'];
  severity: FlagSeverity;
  title: Scalars['string'];
};

export type Fleet = {
  __typename?: 'fleet';
  createdAt: Scalars['Time'];
  dotNumber: Scalars['string'];
  id: Scalars['string'];
  name: Scalars['string'];
  updatedAt?: Maybe<Scalars['Time']>;
};

export type FleetSafetyReport = {
  __typename?: 'fleetSafetyReport';
  DotRating: DotRating;
  GeneralTrends: Array<MonthlyValues>;
  InsuranceSummary: Array<InsuranceRecord>;
  LocationStats?: Maybe<GeoJson>;
  OOSSummary: Array<OosSummary>;
  ShipperList: Array<ShipperData>;
  TspProvider: Tsp;
  VehiclesStatsList?: Maybe<VehiclesStatsList>;
  ViolationStats?: Maybe<ViolationsStatsList>;
  basicScores: Array<BasicScore>;
  createdAt: Scalars['Time'];
  createdBy: Scalars['string'];
  datagovInspections: Array<DatagovInspection>;
  dotNumber: Scalars['string'];
  flags: FlagConnection;
  fleet?: Maybe<Fleet>;
  fmcsaAuthStatus: Status;
  hasTelematicsConnection: Scalars['bool'];
  id: Scalars['string'];
  inspections: Array<Inspection>;
  inspectionsConnection: InspectionRecordConnection;
  issScores: Array<IssScore>;
  name: Scalars['string'];
  recommendations: RecommendationConnection;
  recommendations_subset: RecommendationConnection;
  starred: Scalars['bool'];
  state?: Maybe<Scalars['string']>;
  telematicsRiskFleetPercentiles: Array<ScoreTrendItem>;
  telematicsRiskVinPercentiles: Array<TelematicsRiskVinPercentile>;
  telematicsVehicles: Array<TelematicsVehicle>;
};

export type FleetSafetyReportLocationStatsArgs = {
  countType: Scalars['string'];
  endTime?: InputMaybe<Scalars['Time']>;
  startTime?: InputMaybe<Scalars['Time']>;
};

export type FleetSafetyReportVehiclesStatsListArgs = {
  endTime?: InputMaybe<Scalars['Time']>;
  startTime?: InputMaybe<Scalars['Time']>;
};

export type FleetSafetyReportViolationStatsArgs = {
  endTime?: InputMaybe<Scalars['Time']>;
  startTime?: InputMaybe<Scalars['Time']>;
};

export type FleetSafetyReportBasicScoresArgs = {
  fromTimestamp?: InputMaybe<Scalars['Time']>;
  toTimestamp?: InputMaybe<Scalars['Time']>;
};

export type FleetSafetyReportDatagovInspectionsArgs = {
  endDate?: InputMaybe<Scalars['Time']>;
  startDate?: InputMaybe<Scalars['Time']>;
};

export type FleetSafetyReportFlagsArgs = {
  after?: InputMaybe<Scalars['string']>;
  before?: InputMaybe<Scalars['string']>;
  category?: InputMaybe<FlagCategory>;
  filterText?: InputMaybe<Scalars['string']>;
  filterTextFields?: InputMaybe<Array<Scalars['string']>>;
  filterType?: InputMaybe<Scalars['string']>;
  first?: InputMaybe<Scalars['int64']>;
  last?: InputMaybe<Scalars['int64']>;
  severity?: InputMaybe<FlagSeverity>;
  sortBy?: InputMaybe<Scalars['string']>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type FleetSafetyReportInspectionsArgs = {
  endDate?: InputMaybe<Scalars['Time']>;
  minViolations?: InputMaybe<Scalars['int64']>;
  startDate?: InputMaybe<Scalars['Time']>;
};

export type FleetSafetyReportInspectionsConnectionArgs = {
  after?: InputMaybe<Scalars['string']>;
  before?: InputMaybe<Scalars['string']>;
  endDate?: InputMaybe<Scalars['Time']>;
  filterText?: InputMaybe<Scalars['string']>;
  filterTextFields?: InputMaybe<Array<Scalars['string']>>;
  filterType?: InputMaybe<Scalars['string']>;
  first?: InputMaybe<Scalars['int64']>;
  last?: InputMaybe<Scalars['int64']>;
  minViolations?: InputMaybe<Scalars['int64']>;
  sortBy?: InputMaybe<Scalars['string']>;
  sortOrder?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<Scalars['Time']>;
};

export type FleetSafetyReportIssScoresArgs = {
  fromTimestamp?: InputMaybe<Scalars['Time']>;
  toTimestamp?: InputMaybe<Scalars['Time']>;
};

export type FleetSafetyReportRecommendationsArgs = {
  after?: InputMaybe<Scalars['string']>;
  before?: InputMaybe<Scalars['string']>;
  filterText?: InputMaybe<Scalars['string']>;
  filterTextFields?: InputMaybe<Array<Scalars['string']>>;
  filterType?: InputMaybe<Scalars['string']>;
  first?: InputMaybe<Scalars['int64']>;
  impact?: InputMaybe<RecommendationImpact>;
  last?: InputMaybe<Scalars['int64']>;
  sortBy?: InputMaybe<Scalars['string']>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type FleetSafetyReportRecommendations_SubsetArgs = {
  after?: InputMaybe<Scalars['string']>;
  before?: InputMaybe<Scalars['string']>;
  filterText?: InputMaybe<Scalars['string']>;
  filterTextFields?: InputMaybe<Array<Scalars['string']>>;
  filterType?: InputMaybe<Scalars['string']>;
  first?: InputMaybe<Scalars['int64']>;
  impact?: InputMaybe<RecommendationImpact>;
  last?: InputMaybe<Scalars['int64']>;
  sortBy?: InputMaybe<Scalars['string']>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type FleetSafetyReportTelematicsRiskFleetPercentilesArgs = {
  endTime?: InputMaybe<Scalars['Time']>;
  startTime?: InputMaybe<Scalars['Time']>;
};

export type FleetSafetyReportTelematicsRiskVinPercentilesArgs = {
  endTime?: InputMaybe<Scalars['Time']>;
  startTime?: InputMaybe<Scalars['Time']>;
};

export type FleetSafetyReportTelematicsVehiclesArgs = {
  endTime?: InputMaybe<Scalars['Time']>;
  startTime?: InputMaybe<Scalars['Time']>;
};

export type FleetUserInvite = {
  __typename?: 'fleetUserInvite';
  shareID: Scalars['string'];
};

export type InitiateTelematicsResponse = {
  __typename?: 'initiateTelematicsResponse';
  handleID: Scalars['string'];
  location?: Maybe<Scalars['string']>;
  state?: Maybe<Scalars['string']>;
  tsp: Tsp;
};

export type Inspection = {
  __typename?: 'inspection';
  combinationVehicleGrossWeight?: Maybe<Scalars['int']>;
  countyCode: Scalars['string'];
  countyName: Scalars['string'];
  dotNumber: Scalars['int64'];
  driver?: Maybe<Driver>;
  driverOOSTotal: Scalars['int'];
  hazmatOOSTotal: Scalars['int'];
  hazmatPlacardReq: Scalars['bool'];
  hazmatViolationsSent: Scalars['int'];
  inspectionDate: Scalars['Time'];
  inspectionID: Scalars['int64'];
  inspectionLevel: InspectionLevel;
  location?: Maybe<Scalars['string']>;
  publicVINs: Array<Scalars['string']>;
  publishedDate: Scalars['Time'];
  region?: Maybe<Scalars['string']>;
  reportNumber: Scalars['string'];
  reportState: Scalars['string'];
  rowID: Scalars['string'];
  shipperName?: Maybe<Scalars['string']>;
  telematicsAssignments: Array<TelematicsAssignment>;
  timeWeight: Scalars['int'];
  totalBASICViols: Scalars['int'];
  totalOOSViolations: Scalars['int'];
  vehicleOOSTotal: Scalars['int'];
  vehicles: Array<Vehicle>;
  violations: Array<Violation>;
  wasLocalEnforcement?: Maybe<Scalars['bool']>;
  wasPostAccident?: Maybe<Scalars['bool']>;
  wasSizeWeightEnforcement?: Maybe<Scalars['bool']>;
  wasTrafficEnforcement?: Maybe<Scalars['bool']>;
};

export type Recommendation = {
  __typename?: 'recommendation';
  engine: Scalars['int'];
  id: Scalars['string'];
  impact: RecommendationImpact;
  sections: Array<RecommendationSection>;
  title: Scalars['string'];
};

export type Role = {
  __typename?: 'role';
  agency?: Maybe<Agency>;
  agencyID?: Maybe<Scalars['string']>;
  createdAt: Scalars['Time'];
  domain: Scalars['string'];
  fleetID?: Maybe<Scalars['string']>;
  group: RoleGroupEnum;
  id: Scalars['string'];
  updatedAt?: Maybe<Scalars['Time']>;
  userID: Scalars['string'];
};

export type TelematicsAssignment = {
  __typename?: 'telematicsAssignment';
  assignedDurationMs: Scalars['int64'];
  driver?: Maybe<TelematicsDriver>;
  vehicle?: Maybe<TelematicsVehicle>;
};

export type TelematicsDriver = {
  __typename?: 'telematicsDriver';
  id: Scalars['string'];
  licenseNumber: Scalars['string'];
  licenseState: Scalars['string'];
  name: Scalars['string'];
};

export type TelematicsRiskVinPercentile = {
  __typename?: 'telematicsRiskVinPercentile';
  scores: Array<VehicleScoreTrendItem>;
  vin: Scalars['string'];
};

export type User = {
  __typename?: 'user';
  agencies: Array<Agency>;
  createdAt: Scalars['Time'];
  deletedAt?: Maybe<Scalars['Time']>;
  email: Scalars['string'];
  firstName: Scalars['string'];
  id: Scalars['string'];
  lastLoginAt?: Maybe<Scalars['Time']>;
  lastName: Scalars['string'];
  phoneNumber?: Maybe<Scalars['string']>;
  profilePicture?: Maybe<Scalars['string']>;
  roles: Array<Role>;
  safetyReports: Array<FleetSafetyReport>;
  title?: Maybe<Scalars['string']>;
  updatedAt?: Maybe<Scalars['Time']>;
};

export type Vehicle = {
  __typename?: 'vehicle';
  company: Scalars['string'];
  make: Scalars['string'];
  model: Scalars['string'];
  vin: Scalars['string'];
};

export type VehicleScoreTrendItem = {
  __typename?: 'vehicleScoreTrendItem';
  fleetID: Scalars['string'];
  handleID: Scalars['string'];
  score: Scalars['float64'];
  telematicsAssignments: Array<TelematicsAssignment>;
  timestamp: Scalars['Time'];
  vin: Scalars['string'];
};

export type Violation = {
  __typename?: 'violation';
  category: Category;
  code: Scalars['string'];
  description: Scalars['string'];
  group: ViolationGroup;
  humanReadableCode: Scalars['string'];
  isDSMS: Scalars['bool'];
  oosIndicator: Scalars['bool'];
  oosWeight: Scalars['int'];
  publishedDate: Scalars['Time'];
  rowID: Scalars['string'];
  severityWeight: Scalars['int'];
  timeWeight: Scalars['int'];
  totalSeverityWeight: Scalars['int'];
  violationID?: Maybe<Scalars['int']>;
};

export type ViolationGroup = {
  __typename?: 'violationGroup';
  category: Category;
  humanReadable: Scalars['string'];
  name: Scalars['string'];
  severity: Scalars['int'];
};

export type InitiateTelematicsMutationVariables = Exact<{
  safetyReportId: Scalars['string'];
  tsp: Tsp;
}>;

export type InitiateTelematicsMutation = {
  __typename?: 'Mutation';
  initiateTelematics?: {
    __typename?: 'initiateTelematicsResponse';
    handleID: string;
    location?: string | null;
    state?: string | null;
    tsp: Tsp;
  } | null;
};

export type FleetDetailsQueryVariables = Exact<{
  reportId: Scalars['string'];
}>;

export type FleetDetailsQuery = {
  __typename?: 'Query';
  fleetSafetyReport?: {
    __typename?: 'fleetSafetyReport';
    id: string;
    name: string;
    starred: boolean;
    dotNumber: string;
    fmcsaAuthStatus: Status;
    hasTelematicsConnection: boolean;
    createdBy: string;
    TspProvider: Tsp;
  } | null;
};

export type FleetSafetyFragment = {
  __typename?: 'fleetSafetyReport';
  id: string;
  name: string;
  starred: boolean;
  dotNumber: string;
  fmcsaAuthStatus: Status;
  hasTelematicsConnection: boolean;
  createdBy: string;
  TspProvider: Tsp;
};

export type FleetStarMutationVariables = Exact<{
  markStar: Scalars['bool'];
  reportId: Scalars['string'];
}>;

export type FleetStarMutation = {
  __typename?: 'Mutation';
  setFleetStarredStatus: boolean;
};

export type InviteFleetUserMutationVariables = Exact<{
  fleetId: Scalars['string'];
  email: Scalars['string'];
}>;

export type InviteFleetUserMutation = {
  __typename?: 'Mutation';
  inviteFleetUser?: { __typename?: 'fleetUserInvite'; shareID: string } | null;
};

export type CreateReportMutationVariables = Exact<{
  dotNumber: Scalars['string'];
}>;

export type CreateReportMutation = {
  __typename?: 'Mutation';
  createFleetSafetyReport?: {
    __typename?: 'fleetSafetyReport';
    id: string;
    name: string;
    dotNumber: string;
    createdAt: string;
  } | null;
};

export type PoliciesQueryVariables = Exact<{
  activeDateIn: Scalars['Time'];
  dotNumber: Scalars['string'];
  policyStates?: InputMaybe<Array<PolicyState> | PolicyState>;
}>;

export type PoliciesQuery = {
  __typename?: 'Query';
  policies: Array<{
    __typename?: 'Policy';
    id: string;
    policyNumber: string;
    coverages: Array<PolicyCoverageEnums>;
    state: PolicyState;
  }>;
};

export type SafetyReportQueryVariables = Exact<{
  reportId: Scalars['string'];
}>;

export type SafetyReportQuery = {
  __typename?: 'Query';
  fleetSafetyReport?: {
    __typename?: 'fleetSafetyReport';
    dotNumber: string;
    createdBy: string;
    id: string;
  } | null;
};

export type ActivateUserMutationVariables = Exact<{
  firstName: Scalars['string'];
  lastName: Scalars['string'];
  email: Scalars['string'];
  password: Scalars['string'];
}>;

export type ActivateUserMutation = {
  __typename?: 'Mutation';
  activateUser?: {
    __typename?: 'activateUserResponse';
    expiration: string;
    fleetSafetyReportId?: string | null;
    sessionId: string;
  } | null;
};

export type ClaimsPresignedUploadLinksQueryVariables = Exact<{
  policyNumber: Scalars['string'];
  fileNames: Array<Scalars['string']> | Scalars['string'];
}>;

export type ClaimsPresignedUploadLinksQuery = {
  __typename?: 'Query';
  claimsPresignedUploadLinks: Array<{
    __typename?: 'PresignedUploadLink';
    url: string;
    key: string;
  }>;
};

export type ClaimsByDotNumberQueryVariables = Exact<{
  dotNumber: Scalars['string'];
}>;

export type ClaimsByDotNumberQuery = {
  __typename?: 'Query';
  claimsByDOTNumber: Array<{
    __typename?: 'claims';
    id: string;
    externalId: string;
    lineOfBusiness: string;
    policyNumber: string;
    status: ClaimStatus;
    reportedBy?: string | null;
    reportedAt: string;
    modifiedAt: string;
    canSubmitFeedback: boolean;
    feedbacks: Array<{
      __typename?: 'ClaimFeedback';
      id: string;
      createdBy: string;
      claimId: string;
      rating: number;
      value?: string | null;
    }>;
  }>;
};

export type CreateClaimFeedbackMutationVariables = Exact<{
  claimId: Scalars['string'];
  rating: Scalars['int'];
  value: Scalars['string'];
  category: FeedbackCategory;
}>;

export type CreateClaimFeedbackMutation = {
  __typename?: 'Mutation';
  createClaimFeedback?: {
    __typename?: 'ClaimFeedback';
    id: string;
    createdBy: string;
    claimId: string;
    rating: number;
    value?: string | null;
  } | null;
};

export type CreateFnolMutationVariables = Exact<{
  policyNumber: Scalars['string'];
  description?: InputMaybe<Scalars['string']>;
  noticeType: FnolNoticeType;
  lossLocation?: InputMaybe<Scalars['string']>;
  lossState: Scalars['string'];
  lossDate: Scalars['Time'];
  injuriesInvolved?: InputMaybe<Scalars['bool']>;
  police?: InputMaybe<Police_InputObject>;
  reporter: Reporter_InputObject;
  insuredVehicles?: InputMaybe<
    Array<ClaimVehicle_InputObject> | ClaimVehicle_InputObject
  >;
  otherVehicles?: InputMaybe<
    Array<ClaimVehicle_InputObject> | ClaimVehicle_InputObject
  >;
  insuredName?: InputMaybe<Scalars['string']>;
  lineOfBusiness: LineOfBusiness;
  attachmentKeys?: InputMaybe<Array<Scalars['string']> | Scalars['string']>;
}>;

export type CreateFnolMutation = {
  __typename?: 'Mutation';
  createFNOL?: {
    __typename?: 'Fnol';
    id: string;
    clientClaimNumber: string;
    policyNumber: string;
    status: FnolStatus;
    createdAt: string;
  } | null;
};

export type ReportsQueryVariables = Exact<{ [key: string]: never }>;

export type ReportsQuery = {
  __typename?: 'Query';
  fnols: Array<{
    __typename?: 'Fnol';
    clientClaimNumber: string;
    createdAt: string;
    id: string;
    incidentDescription: string;
    lossDatetime: string;
    lossLocation: string;
    lossState: string;
    nonVehiclePropertyDamageDescription?: string | null;
    noticeType: FnolNoticeType;
    policeAgencyName?: string | null;
    policeReportNumber?: string | null;
    policyNumber: string;
    createdBy: string;
    status: FnolStatus;
  }>;
};

export type TelematicsAssignmentFragment = {
  __typename?: 'telematicsAssignment';
  assignedDurationMs: number;
  driver?: {
    __typename?: 'telematicsDriver';
    id: string;
    name: string;
    licenseNumber: string;
  } | null;
  vehicle?: { __typename?: 'TelematicsVehicle'; vin: string } | null;
};

export type DriversQueryVariables = Exact<{
  dotNumber: Scalars['string'];
  startTime?: InputMaybe<Scalars['Time']>;
  endTime?: InputMaybe<Scalars['Time']>;
  mileagesStartTime?: InputMaybe<Scalars['Time']>;
}>;

export type DriversQuery = {
  __typename?: 'Query';
  fleetSafetyReport?: {
    __typename?: 'fleetSafetyReport';
    id: string;
    dotNumber: string;
    telematicsRiskVinPercentiles: Array<{
      __typename?: 'telematicsRiskVinPercentile';
      vin: string;
      scores: Array<{
        __typename?: 'vehicleScoreTrendItem';
        score: number;
        timestamp: string;
        telematicsAssignments: Array<{
          __typename?: 'telematicsAssignment';
          assignedDurationMs: number;
          driver?: {
            __typename?: 'telematicsDriver';
            id: string;
            name: string;
            licenseNumber: string;
          } | null;
          vehicle?: { __typename?: 'TelematicsVehicle'; vin: string } | null;
        }>;
      }>;
    }>;
    inspections: Array<{
      __typename?: 'inspection';
      inspectionID: number;
      violations: Array<{
        __typename?: 'violation';
        oosIndicator: boolean;
        totalSeverityWeight: number;
      }>;
    }>;
    datagovInspections: Array<{
      __typename?: 'datagovInspection';
      countyName: string;
      inspectionDate: string;
      id: number;
      violations: Array<{
        __typename?: 'datagovViolation';
        id: number;
        severity?: number | null;
        oos?: boolean | null;
      }>;
      telematicsAssignments: Array<{
        __typename?: 'telematicsAssignment';
        assignedDurationMs: number;
        driver?: {
          __typename?: 'telematicsDriver';
          id: string;
          name: string;
          licenseNumber: string;
        } | null;
        vehicle?: { __typename?: 'TelematicsVehicle'; vin: string } | null;
      }>;
      vehicles: Array<{
        __typename?: 'datagovVehicle';
        vin?: string | null;
        make?: string | null;
      }>;
    }>;
    VehiclesStatsList?: {
      __typename?: 'VehiclesStatsList';
      vehicles: Array<{
        __typename?: 'VehicleStat';
        vehicleTSPName?: string | null;
        vIN?: string | null;
        vehicleName?: string | null;
      }>;
    } | null;
    telematicsVehicles: Array<{
      __typename?: 'TelematicsVehicle';
      vin: string;
      mileage?: { __typename?: 'VehicleMileage'; distanceMiles: number } | null;
    }>;
  } | null;
};

export type FlagsQueryVariables = Exact<{
  reportId: Scalars['string'];
  count?: InputMaybe<Scalars['int64']>;
  severity?: InputMaybe<FlagSeverity>;
}>;

export type FlagsQuery = {
  __typename?: 'Query';
  fleetSafetyReport?: {
    __typename?: 'fleetSafetyReport';
    id: string;
    flags: {
      __typename?: 'FlagConnection';
      totalCount: number;
      pageInfo: {
        __typename?: 'PageInfo';
        pages: Array<string>;
        hasNextPage: boolean;
        hasPrevPage: boolean;
        startCursor: string;
        endCursor: string;
      };
      edges: Array<{
        __typename?: 'FlagEdge';
        cursor: string;
        node: {
          __typename?: 'flag';
          id: string;
          severity: FlagSeverity;
          title: string;
          category: FlagCategory;
          description: string;
          chart?: {
            __typename?: 'Chart';
            title?: string | null;
            url?: string | null;
            table?: {
              __typename?: 'Table';
              records: Array<Array<string>>;
            } | null;
          } | null;
        };
      }>;
    };
  } | null;
};

export type GetFlagsCountBySeverityQueryVariables = Exact<{
  reportId: Scalars['string'];
}>;

export type GetFlagsCountBySeverityQuery = {
  __typename?: 'Query';
  fleetSafetyReport?: {
    __typename?: 'fleetSafetyReport';
    id: string;
    Critical: { __typename?: 'FlagConnection'; totalCount: number };
    Minor: { __typename?: 'FlagConnection'; totalCount: number };
    Moderate: { __typename?: 'FlagConnection'; totalCount: number };
    all: { __typename?: 'FlagConnection'; totalCount: number };
  } | null;
};

export type GetFlagsCountByCategoryQueryVariables = Exact<{
  reportId: Scalars['string'];
}>;

export type GetFlagsCountByCategoryQuery = {
  __typename?: 'Query';
  fleetSafetyReport?: {
    __typename?: 'fleetSafetyReport';
    id: string;
    all: { __typename?: 'FlagConnection'; totalCount: number };
    Basics: { __typename?: 'FlagConnection'; totalCount: number };
    RelatedEntities: { __typename?: 'FlagConnection'; totalCount: number };
    Crashes: { __typename?: 'FlagConnection'; totalCount: number };
    Insurance: { __typename?: 'FlagConnection'; totalCount: number };
    Violations: { __typename?: 'FlagConnection'; totalCount: number };
    DotRating: { __typename?: 'FlagConnection'; totalCount: number };
  } | null;
};

export type GetViolationMapDataQueryVariables = Exact<{
  reportId: Scalars['string'];
  violationType: Scalars['string'];
  fromTimestamp?: InputMaybe<Scalars['Time']>;
  toTimestamp?: InputMaybe<Scalars['Time']>;
}>;

export type GetViolationMapDataQuery = {
  __typename?: 'Query';
  fleetSafetyReport?: {
    __typename?: 'fleetSafetyReport';
    id: string;
    dotNumber: string;
    fmcsaAuthStatus: Status;
    basicScores: Array<{ __typename?: 'basicScore'; scoreDate: string }>;
    LocationStats?: {
      __typename?: 'GeoJSON';
      features: Array<{
        __typename?: 'GeoFeature';
        type?: string | null;
        properties?: {
          __typename?: 'Properties';
          name?: string | null;
          countyCode?: string | null;
          violation_count?: number | null;
          violation_percentage?: number | null;
          inspection_count?: number | null;
          inspection_percentage?: number | null;
          inspectionRecords: Array<{
            __typename?: 'inspection';
            violations: Array<{ __typename?: 'violation'; category: Category }>;
          }>;
        } | null;
        geometry?: {
          __typename?: 'Geometry';
          type: string;
          coordinates: Array<Array<Array<number>>>;
        } | null;
      }>;
    } | null;
  } | null;
};

export type FleetSearchQueryVariables = Exact<{
  searchText: Scalars['string'];
}>;

export type FleetSearchQuery = {
  __typename?: 'Query';
  fetchFleetSafetySearch: Array<{
    __typename?: 'FleetSearchResult';
    dotNumber: string;
    name: string;
    state?: string | null;
    lastViewedAt?: string | null;
  }>;
};

export type SearchHistoryQueryVariables = Exact<{ [key: string]: never }>;

export type SearchHistoryQuery = {
  __typename?: 'Query';
  fleetSafetyReports: {
    __typename?: 'FleetSafetyReportConnection';
    totalCount: number;
    pageInfo: {
      __typename?: 'PageInfo';
      pages: Array<string>;
      hasNextPage: boolean;
      hasPrevPage: boolean;
      startCursor: string;
      endCursor: string;
    };
    edges: Array<{
      __typename?: 'FleetSafetyReportEdge';
      cursor: string;
      node: {
        __typename?: 'fleetSafetyReport';
        id: string;
        name: string;
        dotNumber: string;
        createdAt: string;
        starred: boolean;
      };
    }>;
  };
};

export type GetBasicRecommendationQueryVariables = Exact<{
  reportId: Scalars['string'];
}>;

export type GetBasicRecommendationQuery = {
  __typename?: 'Query';
  fleetSafetyReport?: {
    __typename?: 'fleetSafetyReport';
    id: string;
    recommendations: {
      __typename?: 'RecommendationConnection';
      edges: Array<{
        __typename?: 'RecommendationEdge';
        node: {
          __typename?: 'recommendation';
          sections: Array<{
            __typename?: 'RecommendationSection';
            reasons: Array<{
              __typename?: 'Reason';
              vINs: Array<string>;
              link?: string | null;
              weightage?: number | null;
              violationCount?: number | null;
              violationGroup?: string | null;
              basicCategory?: string | null;
            }>;
          }>;
        };
      }>;
    };
  } | null;
};

export type BasicScoresFragment = {
  __typename?: 'basicScore';
  discount?: number | null;
  scoreDate: string;
  scores: Array<{
    __typename?: 'BasicScoreDetail';
    name: string;
    percentile?: number | null;
    threshold: number;
    measure?: number | null;
  }>;
};

export type GetBasicScoresQueryVariables = Exact<{
  reportId: Scalars['string'];
  fromTimestamp?: InputMaybe<Scalars['Time']>;
  toTimestamp?: InputMaybe<Scalars['Time']>;
}>;

export type GetBasicScoresQuery = {
  __typename?: 'Query';
  fleetSafetyReport?: {
    __typename?: 'fleetSafetyReport';
    id: string;
    dotNumber: string;
    basicScores: Array<{
      __typename?: 'basicScore';
      discount?: number | null;
      scoreDate: string;
      scores: Array<{
        __typename?: 'BasicScoreDetail';
        name: string;
        percentile?: number | null;
        threshold: number;
        measure?: number | null;
      }>;
    }>;
  } | null;
};

export type FlagsOverviewQueryVariables = Exact<{
  reportId: Scalars['string'];
  count?: InputMaybe<Scalars['int64']>;
}>;

export type FlagsOverviewQuery = {
  __typename?: 'Query';
  fleetSafetyReport?: {
    __typename?: 'fleetSafetyReport';
    id: string;
    flags: {
      __typename?: 'FlagConnection';
      edges: Array<{
        __typename?: 'FlagEdge';
        node: {
          __typename?: 'flag';
          id: string;
          severity: FlagSeverity;
          title: string;
          category: FlagCategory;
        };
      }>;
    };
    Critical: { __typename?: 'FlagConnection'; totalCount: number };
    Minor: { __typename?: 'FlagConnection'; totalCount: number };
    Moderate: { __typename?: 'FlagConnection'; totalCount: number };
  } | null;
};

export type CountsBySeverityFragment = {
  __typename?: 'fleetSafetyReport';
  Critical: { __typename?: 'FlagConnection'; totalCount: number };
  Minor: { __typename?: 'FlagConnection'; totalCount: number };
  Moderate: { __typename?: 'FlagConnection'; totalCount: number };
};

export type FleetRatingQueryVariables = Exact<{
  reportId: Scalars['string'];
  fromTimestamp?: InputMaybe<Scalars['Time']>;
  toTimestamp?: InputMaybe<Scalars['Time']>;
}>;

export type FleetRatingQuery = {
  __typename?: 'Query';
  fleetSafetyReport?: {
    __typename?: 'fleetSafetyReport';
    id: string;
    dotNumber: string;
    basicScores: Array<{
      __typename?: 'basicScore';
      discount?: number | null;
      scoreDate: string;
      scores: Array<{
        __typename?: 'BasicScoreDetail';
        name: string;
        percentile?: number | null;
        threshold: number;
        measure?: number | null;
      }>;
    }>;
    issScores: Array<{
      __typename?: 'ISSScore';
      year: number;
      month: number;
      value?: number | null;
    }>;
  } | null;
};

export type IssScoresFragment = {
  __typename?: 'ISSScore';
  year: number;
  month: number;
  value?: number | null;
};

export type PrintSafetyReportMutationVariables = Exact<{
  reportID: Scalars['string'];
}>;

export type PrintSafetyReportMutation = {
  __typename?: 'Mutation';
  printFleetSafetyReport?: {
    __typename?: 'PrintFleetSafetyReportLink';
    id: string;
    url: string;
  } | null;
};

export type RecommendationsOverviewQueryVariables = Exact<{
  reportId: Scalars['string'];
  count?: InputMaybe<Scalars['int64']>;
}>;

export type RecommendationsOverviewQuery = {
  __typename?: 'Query';
  fleetSafetyReport?: {
    __typename?: 'fleetSafetyReport';
    id: string;
    recommendations: {
      __typename?: 'RecommendationConnection';
      edges: Array<{
        __typename?: 'RecommendationEdge';
        node: {
          __typename?: 'recommendation';
          id: string;
          impact: RecommendationImpact;
          title: string;
        };
      }>;
    };
  } | null;
};

export type ShareSafetyReportMutationVariables = Exact<{
  reportID: Scalars['string'];
}>;

export type ShareSafetyReportMutation = {
  __typename?: 'Mutation';
  shareSafetyReport?: {
    __typename?: 'SafetyReportShare';
    id: string;
    expiresAt?: string | null;
  } | null;
};

export type TelematicsRiskScoreQueryVariables = Exact<{
  reportId: Scalars['string'];
  fromTimestamp?: InputMaybe<Scalars['Time']>;
  toTimestamp?: InputMaybe<Scalars['Time']>;
}>;

export type TelematicsRiskScoreQuery = {
  __typename?: 'Query';
  fleetSafetyReport?: {
    __typename?: 'fleetSafetyReport';
    id: string;
    dotNumber: string;
    hasTelematicsConnection: boolean;
    TspProvider: Tsp;
    telematicsRiskFleetPercentiles: Array<{
      __typename?: 'ScoreTrendItem';
      score: number;
      timestamp: string;
      isConfidentScore: boolean;
      lowConfidenceReason?: number | null;
    }>;
  } | null;
};

export type AccidentQueryVariables = Exact<{
  reportId: Scalars['string'];
}>;

export type AccidentQuery = {
  __typename?: 'Query';
  fleetSafetyReport?: {
    __typename?: 'fleetSafetyReport';
    id: string;
    basicScores: Array<{ __typename?: 'basicScore'; scoreDate: string }>;
    OOSSummary: Array<{
      __typename?: 'OOSSummary';
      category: string;
      inspections: number;
      oOSViolations: number;
      nationalAverage: number;
      oOSPercent: number;
    }>;
  } | null;
};

export type DotRatingQueryVariables = Exact<{
  reportId: Scalars['string'];
}>;

export type DotRatingQuery = {
  __typename?: 'Query';
  fleetSafetyReport?: {
    __typename?: 'fleetSafetyReport';
    id: string;
    DotRating: DotRating;
  } | null;
};

export type InsuranceQueryVariables = Exact<{
  reportId: Scalars['string'];
}>;

export type InsuranceQuery = {
  __typename?: 'Query';
  fleetSafetyReport?: {
    __typename?: 'fleetSafetyReport';
    id: string;
    InsuranceSummary: Array<{
      __typename?: 'InsuranceRecord';
      status?: string | null;
      types: string;
      carrier: string;
      policyNumber?: string | null;
      effectiveDateTo?: string | null;
      effectiveDateFrom: string;
    }>;
  } | null;
};

export type ShippersQueryVariables = Exact<{
  reportId: Scalars['string'];
}>;

export type ShippersQuery = {
  __typename?: 'Query';
  fleetSafetyReport?: {
    __typename?: 'fleetSafetyReport';
    id: string;
    ShipperList: Array<{
      __typename?: 'ShipperData';
      shipperName?: string | null;
      inspectionsCount?: number | null;
    }>;
  } | null;
};

export type TrendsQueryVariables = Exact<{
  reportId: Scalars['string'];
}>;

export type TrendsQuery = {
  __typename?: 'Query';
  fleetSafetyReport?: {
    __typename?: 'fleetSafetyReport';
    id: string;
    GeneralTrends: Array<{
      __typename?: 'MonthlyValues';
      year: number;
      month: number;
      pU?: number | null;
      utilization?: number | null;
      mileage?: number | null;
    }>;
  } | null;
};

export type RecommendationsQueryVariables = Exact<{
  reportId: Scalars['string'];
  count?: InputMaybe<Scalars['int64']>;
}>;

export type RecommendationsQuery = {
  __typename?: 'Query';
  fleetSafetyReport?: {
    __typename?: 'fleetSafetyReport';
    id: string;
    recommendations: {
      __typename?: 'RecommendationConnection';
      totalCount: number;
      pageInfo: {
        __typename?: 'PageInfo';
        pages: Array<string>;
        hasNextPage: boolean;
        hasPrevPage: boolean;
        startCursor: string;
        endCursor: string;
      };
      edges: Array<{
        __typename?: 'RecommendationEdge';
        cursor: string;
        node: {
          __typename?: 'recommendation';
          id: string;
          engine: number;
          impact: RecommendationImpact;
          title: string;
          sections: Array<{
            __typename?: 'RecommendationSection';
            markdown: string;
            chart?: {
              __typename?: 'Chart';
              url?: string | null;
              title?: string | null;
              geoJSON?: {
                __typename?: 'GeoJSON';
                features: Array<{
                  __typename?: 'GeoFeature';
                  type?: string | null;
                  properties?: {
                    __typename?: 'Properties';
                    name?: string | null;
                    violation_count?: number | null;
                    violation_percentage?: number | null;
                  } | null;
                  geometry?: {
                    __typename?: 'Geometry';
                    type: string;
                    coordinates: Array<Array<Array<number>>>;
                  } | null;
                }>;
              } | null;
              projection?: {
                __typename?: 'LineChart';
                threshold?: number | null;
                xLabel?: string | null;
                yLabel?: string | null;
                data: Array<{
                  __typename?: 'Data';
                  xVal?: string | null;
                  yVals: Array<number>;
                }>;
                lineConfigs: Array<{
                  __typename?: 'LineConfig';
                  name?: string | null;
                  dashed?: boolean | null;
                }>;
              } | null;
            } | null;
          }>;
        };
      }>;
    };
  } | null;
};

export type GetUserNotificationPreferencesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetUserNotificationPreferencesQuery = {
  __typename?: 'Query';
  userNotificationPreferences: Array<{
    __typename?: 'UserWorkflowPreference';
    email: boolean;
    sms: boolean;
    workflow: string;
  }>;
};

export type GetUserContactInfoQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['string']>;
}>;

export type GetUserContactInfoQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'user';
    id: string;
    firstName: string;
    lastName: string;
    phoneNumber?: string | null;
  } | null;
};

export type UpdateUserNotificationPreferencesMutationVariables = Exact<{
  preferences:
    | Array<UserWorkflowPreference_InputObject>
    | UserWorkflowPreference_InputObject;
}>;

export type UpdateUserNotificationPreferencesMutation = {
  __typename?: 'Mutation';
  updateUserNotificationPreferences: Array<{
    __typename?: 'UserWorkflowPreference';
    workflow: string;
    email: boolean;
    sms: boolean;
  }>;
};

export type UpdateUserContactInfoMutationVariables = Exact<{
  id: Scalars['string'];
  email?: InputMaybe<Scalars['string']>;
  firstName?: InputMaybe<Scalars['string']>;
  lastName?: InputMaybe<Scalars['string']>;
  phoneNumber?: InputMaybe<Scalars['string']>;
}>;

export type UpdateUserContactInfoMutation = {
  __typename?: 'Mutation';
  patchUser?: {
    __typename?: 'user';
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber?: string | null;
  } | null;
};

export type FinalizeTelematicsMutationVariables = Exact<{
  authCode: Scalars['string'];
  error?: InputMaybe<Scalars['string']>;
  scope?: InputMaybe<Scalars['string']>;
  state: Scalars['string'];
  tsp: Tsp;
}>;

export type FinalizeTelematicsMutation = {
  __typename?: 'Mutation';
  finalizeTelematics?: {
    __typename?: 'finalizeTelematicsResponse';
    error?: string | null;
    handleID: string;
    safetyReportID: string;
  } | null;
};

export type VehiclesQueryVariables = Exact<{
  reportId: Scalars['string'];
  fromTimestamp?: InputMaybe<Scalars['Time']>;
  toTimestamp?: InputMaybe<Scalars['Time']>;
  telematicsStartTime?: InputMaybe<Scalars['Time']>;
  telematicsEndTime?: InputMaybe<Scalars['Time']>;
}>;

export type VehiclesQuery = {
  __typename?: 'Query';
  fleetSafetyReport?: {
    __typename?: 'fleetSafetyReport';
    id: string;
    hasTelematicsConnection: boolean;
    dotNumber: string;
    VehiclesStatsList?: {
      __typename?: 'VehiclesStatsList';
      vehicles: Array<{
        __typename?: 'VehicleStat';
        vehicleTSPName?: string | null;
        vIN?: string | null;
        riskScore?: number | null;
        vehicleName?: string | null;
        violationSeverityWeight?: number | null;
        violationSeverityTimeWeighted?: number | null;
        keyBehaviour?: string | null;
      }>;
    } | null;
    telematicsRiskVinPercentiles: Array<{
      __typename?: 'telematicsRiskVinPercentile';
      vin: string;
      scores: Array<{
        __typename?: 'vehicleScoreTrendItem';
        score: number;
        timestamp: string;
        telematicsAssignments: Array<{
          __typename?: 'telematicsAssignment';
          assignedDurationMs: number;
          driver?: {
            __typename?: 'telematicsDriver';
            id: string;
            name: string;
          } | null;
        }>;
      }>;
    }>;
  } | null;
};

export type CompleteDriverViolationFetchMutationVariables = Exact<{
  twoFA: Scalars['string'];
  browserWSEndpoint: Scalars['string'];
  reportID: Scalars['string'];
  twoFactorUrl: Scalars['string'];
}>;

export type CompleteDriverViolationFetchMutation = {
  __typename?: 'Mutation';
  completeDriverViolationFetch: boolean;
};

export type InitiateDriverViolationFetchQueryVariables = Exact<{
  username: Scalars['string'];
  password: Scalars['string'];
  reportId: Scalars['string'];
}>;

export type InitiateDriverViolationFetchQuery = {
  __typename?: 'Query';
  initiateDriverViolationFetch?: {
    __typename?: 'InitiateDriverViolationFetchResults';
    browserWSEndpoint: string;
    twoFactorUrl: string;
  } | null;
};

export type ViolationsQueryVariables = Exact<{
  reportId: Scalars['string'];
  fromTimestamp?: InputMaybe<Scalars['Time']>;
  toTimestamp?: InputMaybe<Scalars['Time']>;
  datagovStartDate?: InputMaybe<Scalars['Time']>;
  datagovEndDate?: InputMaybe<Scalars['Time']>;
}>;

export type ViolationsQuery = {
  __typename?: 'Query';
  fleetSafetyReport?: {
    __typename?: 'fleetSafetyReport';
    id: string;
    dotNumber: string;
    fmcsaAuthStatus: Status;
    inspections: Array<{
      __typename?: 'inspection';
      rowID: string;
      inspectionID: number;
      inspectionDate: string;
      countyCode: string;
      countyName: string;
      location?: string | null;
      publicVINs: Array<string>;
      violations: Array<{
        __typename?: 'violation';
        rowID: string;
        violationID?: number | null;
        code: string;
        category: Category;
        oosIndicator: boolean;
        timeWeight: number;
        severityWeight: number;
        totalSeverityWeight: number;
        humanReadableCode: string;
        description: string;
        isDSMS: boolean;
        oosWeight: number;
        publishedDate: string;
        group: {
          __typename?: 'violationGroup';
          name: string;
          category: Category;
          severity: number;
          humanReadable: string;
        };
      }>;
      vehicles: Array<{ __typename?: 'vehicle'; vin: string }>;
      driver?: {
        __typename?: 'driver';
        id: string;
        name: string;
        licenseNumber: string;
      } | null;
    }>;
    datagovInspections: Array<{
      __typename?: 'datagovInspection';
      id: number;
      inspectionDate: string;
      location?: string | null;
      locationDesc?: string | null;
      countyCode: string;
      countyName: string;
      countyCodeState?: string | null;
      violTotal: number;
      violations: Array<{
        __typename?: 'datagovViolation';
        id: number;
        oos?: boolean | null;
        severity?: number | null;
        partNumber: string;
        partNumberSection: string;
        code?: string | null;
        description?: string | null;
        seqNumber: number;
        vehicle?: {
          __typename?: 'datagovVehicle';
          id: number;
          vin?: string | null;
        } | null;
        group?: {
          __typename?: 'violationGroup';
          name: string;
          category: Category;
          severity: number;
          humanReadable: string;
        } | null;
      }>;
      vehicles: Array<{
        __typename?: 'datagovVehicle';
        id: number;
        vin?: string | null;
        make?: string | null;
        company?: string | null;
        licensePlate?: string | null;
        licenseState?: string | null;
      }>;
    }>;
  } | null;
};

export const FleetSafetyFragmentDoc = gql`
  fragment FleetSafety on fleetSafetyReport {
    id
    name
    starred
    dotNumber
    fmcsaAuthStatus
    hasTelematicsConnection
    createdBy
    TspProvider
  }
`;
export const TelematicsAssignmentFragmentDoc = gql`
  fragment telematicsAssignment on telematicsAssignment {
    driver {
      id
      name
      licenseNumber
    }
    assignedDurationMs
    vehicle {
      vin
    }
  }
`;
export const BasicScoresFragmentDoc = gql`
  fragment BasicScores on basicScore {
    discount
    scores {
      name
      percentile
      threshold
      measure
    }
    scoreDate
  }
`;
export const CountsBySeverityFragmentDoc = gql`
  fragment countsBySeverity on fleetSafetyReport {
    Critical: flags(first: 0, severity: Critical) {
      totalCount
    }
    Minor: flags(first: 0, severity: Minor) {
      totalCount
    }
    Moderate: flags(first: 0, severity: Moderate) {
      totalCount
    }
  }
`;
export const IssScoresFragmentDoc = gql`
  fragment IssScores on ISSScore {
    year
    month
    value
  }
`;
export const InitiateTelematicsDocument = gql`
  mutation InitiateTelematics($safetyReportId: string!, $tsp: TSP!) {
    initiateTelematics(safetyReportID: $safetyReportId, tsp: $tsp) {
      handleID
      location
      state
      tsp
    }
  }
`;
export type InitiateTelematicsMutationFn = Apollo.MutationFunction<
  InitiateTelematicsMutation,
  InitiateTelematicsMutationVariables
>;

/**
 * __useInitiateTelematicsMutation__
 *
 * To run a mutation, you first call `useInitiateTelematicsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitiateTelematicsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initiateTelematicsMutation, { data, loading, error }] = useInitiateTelematicsMutation({
 *   variables: {
 *      safetyReportId: // value for 'safetyReportId'
 *      tsp: // value for 'tsp'
 *   },
 * });
 */
export function useInitiateTelematicsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InitiateTelematicsMutation,
    InitiateTelematicsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InitiateTelematicsMutation,
    InitiateTelematicsMutationVariables
  >(InitiateTelematicsDocument, options);
}
export type InitiateTelematicsMutationHookResult = ReturnType<
  typeof useInitiateTelematicsMutation
>;
export type InitiateTelematicsMutationResult =
  Apollo.MutationResult<InitiateTelematicsMutation>;
export type InitiateTelematicsMutationOptions = Apollo.BaseMutationOptions<
  InitiateTelematicsMutation,
  InitiateTelematicsMutationVariables
>;
export const FleetDetailsDocument = gql`
  query FleetDetails($reportId: string!) {
    fleetSafetyReport(id: $reportId) {
      ...FleetSafety
    }
  }
  ${FleetSafetyFragmentDoc}
`;

/**
 * __useFleetDetailsQuery__
 *
 * To run a query within a React component, call `useFleetDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFleetDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFleetDetailsQuery({
 *   variables: {
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function useFleetDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FleetDetailsQuery,
    FleetDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FleetDetailsQuery, FleetDetailsQueryVariables>(
    FleetDetailsDocument,
    options,
  );
}
export function useFleetDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FleetDetailsQuery,
    FleetDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FleetDetailsQuery, FleetDetailsQueryVariables>(
    FleetDetailsDocument,
    options,
  );
}
export type FleetDetailsQueryHookResult = ReturnType<
  typeof useFleetDetailsQuery
>;
export type FleetDetailsLazyQueryHookResult = ReturnType<
  typeof useFleetDetailsLazyQuery
>;
export type FleetDetailsQueryResult = Apollo.QueryResult<
  FleetDetailsQuery,
  FleetDetailsQueryVariables
>;
export const FleetStarDocument = gql`
  mutation fleetStar($markStar: bool!, $reportId: string!) {
    setFleetStarredStatus(reportId: $reportId, markStarred: $markStar)
  }
`;
export type FleetStarMutationFn = Apollo.MutationFunction<
  FleetStarMutation,
  FleetStarMutationVariables
>;

/**
 * __useFleetStarMutation__
 *
 * To run a mutation, you first call `useFleetStarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFleetStarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fleetStarMutation, { data, loading, error }] = useFleetStarMutation({
 *   variables: {
 *      markStar: // value for 'markStar'
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function useFleetStarMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FleetStarMutation,
    FleetStarMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FleetStarMutation, FleetStarMutationVariables>(
    FleetStarDocument,
    options,
  );
}
export type FleetStarMutationHookResult = ReturnType<
  typeof useFleetStarMutation
>;
export type FleetStarMutationResult = Apollo.MutationResult<FleetStarMutation>;
export type FleetStarMutationOptions = Apollo.BaseMutationOptions<
  FleetStarMutation,
  FleetStarMutationVariables
>;
export const InviteFleetUserDocument = gql`
  mutation InviteFleetUser($fleetId: string!, $email: string!) {
    inviteFleetUser(fleetSafetyReportID: $fleetId, email: $email) {
      shareID
    }
  }
`;
export type InviteFleetUserMutationFn = Apollo.MutationFunction<
  InviteFleetUserMutation,
  InviteFleetUserMutationVariables
>;

/**
 * __useInviteFleetUserMutation__
 *
 * To run a mutation, you first call `useInviteFleetUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteFleetUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteFleetUserMutation, { data, loading, error }] = useInviteFleetUserMutation({
 *   variables: {
 *      fleetId: // value for 'fleetId'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useInviteFleetUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InviteFleetUserMutation,
    InviteFleetUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InviteFleetUserMutation,
    InviteFleetUserMutationVariables
  >(InviteFleetUserDocument, options);
}
export type InviteFleetUserMutationHookResult = ReturnType<
  typeof useInviteFleetUserMutation
>;
export type InviteFleetUserMutationResult =
  Apollo.MutationResult<InviteFleetUserMutation>;
export type InviteFleetUserMutationOptions = Apollo.BaseMutationOptions<
  InviteFleetUserMutation,
  InviteFleetUserMutationVariables
>;
export const CreateReportDocument = gql`
  mutation CreateReport($dotNumber: string!) {
    createFleetSafetyReport(dotNumber: $dotNumber) {
      id
      name
      dotNumber
      createdAt
    }
  }
`;
export type CreateReportMutationFn = Apollo.MutationFunction<
  CreateReportMutation,
  CreateReportMutationVariables
>;

/**
 * __useCreateReportMutation__
 *
 * To run a mutation, you first call `useCreateReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReportMutation, { data, loading, error }] = useCreateReportMutation({
 *   variables: {
 *      dotNumber: // value for 'dotNumber'
 *   },
 * });
 */
export function useCreateReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateReportMutation,
    CreateReportMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateReportMutation,
    CreateReportMutationVariables
  >(CreateReportDocument, options);
}
export type CreateReportMutationHookResult = ReturnType<
  typeof useCreateReportMutation
>;
export type CreateReportMutationResult =
  Apollo.MutationResult<CreateReportMutation>;
export type CreateReportMutationOptions = Apollo.BaseMutationOptions<
  CreateReportMutation,
  CreateReportMutationVariables
>;
export const PoliciesDocument = gql`
  query Policies(
    $activeDateIn: Time!
    $dotNumber: string!
    $policyStates: [PolicyState!]
  ) {
    policies(
      activeDateIn: $activeDateIn
      dotNumber: $dotNumber
      policyStates: $policyStates
    ) {
      id
      policyNumber
      coverages
      state
    }
  }
`;

/**
 * __usePoliciesQuery__
 *
 * To run a query within a React component, call `usePoliciesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePoliciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePoliciesQuery({
 *   variables: {
 *      activeDateIn: // value for 'activeDateIn'
 *      dotNumber: // value for 'dotNumber'
 *      policyStates: // value for 'policyStates'
 *   },
 * });
 */
export function usePoliciesQuery(
  baseOptions: Apollo.QueryHookOptions<PoliciesQuery, PoliciesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PoliciesQuery, PoliciesQueryVariables>(
    PoliciesDocument,
    options,
  );
}
export function usePoliciesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PoliciesQuery,
    PoliciesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PoliciesQuery, PoliciesQueryVariables>(
    PoliciesDocument,
    options,
  );
}
export type PoliciesQueryHookResult = ReturnType<typeof usePoliciesQuery>;
export type PoliciesLazyQueryHookResult = ReturnType<
  typeof usePoliciesLazyQuery
>;
export type PoliciesQueryResult = Apollo.QueryResult<
  PoliciesQuery,
  PoliciesQueryVariables
>;
export const SafetyReportDocument = gql`
  query safetyReport($reportId: string!) {
    fleetSafetyReport(id: $reportId) {
      dotNumber
      createdBy
      id
    }
  }
`;

/**
 * __useSafetyReportQuery__
 *
 * To run a query within a React component, call `useSafetyReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useSafetyReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSafetyReportQuery({
 *   variables: {
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function useSafetyReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    SafetyReportQuery,
    SafetyReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SafetyReportQuery, SafetyReportQueryVariables>(
    SafetyReportDocument,
    options,
  );
}
export function useSafetyReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SafetyReportQuery,
    SafetyReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SafetyReportQuery, SafetyReportQueryVariables>(
    SafetyReportDocument,
    options,
  );
}
export type SafetyReportQueryHookResult = ReturnType<
  typeof useSafetyReportQuery
>;
export type SafetyReportLazyQueryHookResult = ReturnType<
  typeof useSafetyReportLazyQuery
>;
export type SafetyReportQueryResult = Apollo.QueryResult<
  SafetyReportQuery,
  SafetyReportQueryVariables
>;
export const ActivateUserDocument = gql`
  mutation ActivateUser(
    $firstName: string!
    $lastName: string!
    $email: string!
    $password: string!
  ) {
    activateUser(
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
    ) {
      expiration
      fleetSafetyReportId
      sessionId
    }
  }
`;
export type ActivateUserMutationFn = Apollo.MutationFunction<
  ActivateUserMutation,
  ActivateUserMutationVariables
>;

/**
 * __useActivateUserMutation__
 *
 * To run a mutation, you first call `useActivateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateUserMutation, { data, loading, error }] = useActivateUserMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useActivateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ActivateUserMutation,
    ActivateUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ActivateUserMutation,
    ActivateUserMutationVariables
  >(ActivateUserDocument, options);
}
export type ActivateUserMutationHookResult = ReturnType<
  typeof useActivateUserMutation
>;
export type ActivateUserMutationResult =
  Apollo.MutationResult<ActivateUserMutation>;
export type ActivateUserMutationOptions = Apollo.BaseMutationOptions<
  ActivateUserMutation,
  ActivateUserMutationVariables
>;
export const ClaimsPresignedUploadLinksDocument = gql`
  query ClaimsPresignedUploadLinks(
    $policyNumber: string!
    $fileNames: [string!]!
  ) {
    claimsPresignedUploadLinks(
      policyNumber: $policyNumber
      fileNames: $fileNames
    ) {
      url
      key
    }
  }
`;

/**
 * __useClaimsPresignedUploadLinksQuery__
 *
 * To run a query within a React component, call `useClaimsPresignedUploadLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useClaimsPresignedUploadLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClaimsPresignedUploadLinksQuery({
 *   variables: {
 *      policyNumber: // value for 'policyNumber'
 *      fileNames: // value for 'fileNames'
 *   },
 * });
 */
export function useClaimsPresignedUploadLinksQuery(
  baseOptions: Apollo.QueryHookOptions<
    ClaimsPresignedUploadLinksQuery,
    ClaimsPresignedUploadLinksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ClaimsPresignedUploadLinksQuery,
    ClaimsPresignedUploadLinksQueryVariables
  >(ClaimsPresignedUploadLinksDocument, options);
}
export function useClaimsPresignedUploadLinksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClaimsPresignedUploadLinksQuery,
    ClaimsPresignedUploadLinksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ClaimsPresignedUploadLinksQuery,
    ClaimsPresignedUploadLinksQueryVariables
  >(ClaimsPresignedUploadLinksDocument, options);
}
export type ClaimsPresignedUploadLinksQueryHookResult = ReturnType<
  typeof useClaimsPresignedUploadLinksQuery
>;
export type ClaimsPresignedUploadLinksLazyQueryHookResult = ReturnType<
  typeof useClaimsPresignedUploadLinksLazyQuery
>;
export type ClaimsPresignedUploadLinksQueryResult = Apollo.QueryResult<
  ClaimsPresignedUploadLinksQuery,
  ClaimsPresignedUploadLinksQueryVariables
>;
export const ClaimsByDotNumberDocument = gql`
  query ClaimsByDOTNumber($dotNumber: string!) {
    claimsByDOTNumber(dotNumber: $dotNumber) {
      id
      externalId
      lineOfBusiness
      policyNumber
      status
      reportedBy
      reportedAt
      modifiedAt
      canSubmitFeedback
      feedbacks {
        id
        createdBy
        claimId
        rating
        value
      }
    }
  }
`;

/**
 * __useClaimsByDotNumberQuery__
 *
 * To run a query within a React component, call `useClaimsByDotNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useClaimsByDotNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClaimsByDotNumberQuery({
 *   variables: {
 *      dotNumber: // value for 'dotNumber'
 *   },
 * });
 */
export function useClaimsByDotNumberQuery(
  baseOptions: Apollo.QueryHookOptions<
    ClaimsByDotNumberQuery,
    ClaimsByDotNumberQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ClaimsByDotNumberQuery,
    ClaimsByDotNumberQueryVariables
  >(ClaimsByDotNumberDocument, options);
}
export function useClaimsByDotNumberLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClaimsByDotNumberQuery,
    ClaimsByDotNumberQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ClaimsByDotNumberQuery,
    ClaimsByDotNumberQueryVariables
  >(ClaimsByDotNumberDocument, options);
}
export type ClaimsByDotNumberQueryHookResult = ReturnType<
  typeof useClaimsByDotNumberQuery
>;
export type ClaimsByDotNumberLazyQueryHookResult = ReturnType<
  typeof useClaimsByDotNumberLazyQuery
>;
export type ClaimsByDotNumberQueryResult = Apollo.QueryResult<
  ClaimsByDotNumberQuery,
  ClaimsByDotNumberQueryVariables
>;
export const CreateClaimFeedbackDocument = gql`
  mutation createClaimFeedback(
    $claimId: string!
    $rating: int!
    $value: string!
    $category: FeedbackCategory!
  ) {
    createClaimFeedback(
      claimId: $claimId
      rating: $rating
      value: $value
      category: $category
    ) {
      id
      createdBy
      claimId
      rating
      value
    }
  }
`;
export type CreateClaimFeedbackMutationFn = Apollo.MutationFunction<
  CreateClaimFeedbackMutation,
  CreateClaimFeedbackMutationVariables
>;

/**
 * __useCreateClaimFeedbackMutation__
 *
 * To run a mutation, you first call `useCreateClaimFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClaimFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClaimFeedbackMutation, { data, loading, error }] = useCreateClaimFeedbackMutation({
 *   variables: {
 *      claimId: // value for 'claimId'
 *      rating: // value for 'rating'
 *      value: // value for 'value'
 *      category: // value for 'category'
 *   },
 * });
 */
export function useCreateClaimFeedbackMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateClaimFeedbackMutation,
    CreateClaimFeedbackMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateClaimFeedbackMutation,
    CreateClaimFeedbackMutationVariables
  >(CreateClaimFeedbackDocument, options);
}
export type CreateClaimFeedbackMutationHookResult = ReturnType<
  typeof useCreateClaimFeedbackMutation
>;
export type CreateClaimFeedbackMutationResult =
  Apollo.MutationResult<CreateClaimFeedbackMutation>;
export type CreateClaimFeedbackMutationOptions = Apollo.BaseMutationOptions<
  CreateClaimFeedbackMutation,
  CreateClaimFeedbackMutationVariables
>;
export const CreateFnolDocument = gql`
  mutation createFNOL(
    $policyNumber: string!
    $description: string
    $noticeType: FnolNoticeType!
    $lossLocation: string
    $lossState: string!
    $lossDate: Time!
    $injuriesInvolved: bool
    $police: Police_InputObject
    $reporter: Reporter_InputObject!
    $insuredVehicles: [ClaimVehicle_InputObject!]
    $otherVehicles: [ClaimVehicle_InputObject!]
    $insuredName: string
    $lineOfBusiness: LineOfBusiness!
    $attachmentKeys: [string!]
  ) {
    createFNOL(
      policyNumber: $policyNumber
      description: $description
      noticeType: $noticeType
      lossLocation: $lossLocation
      lossState: $lossState
      lossDate: $lossDate
      injuriesInvolved: $injuriesInvolved
      police: $police
      reporter: $reporter
      insuredVehicles: $insuredVehicles
      otherVehicles: $otherVehicles
      insuredName: $insuredName
      lineOfBusiness: $lineOfBusiness
      attachmentKeys: $attachmentKeys
    ) {
      id
      clientClaimNumber
      policyNumber
      status
      createdAt
    }
  }
`;
export type CreateFnolMutationFn = Apollo.MutationFunction<
  CreateFnolMutation,
  CreateFnolMutationVariables
>;

/**
 * __useCreateFnolMutation__
 *
 * To run a mutation, you first call `useCreateFnolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFnolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFnolMutation, { data, loading, error }] = useCreateFnolMutation({
 *   variables: {
 *      policyNumber: // value for 'policyNumber'
 *      description: // value for 'description'
 *      noticeType: // value for 'noticeType'
 *      lossLocation: // value for 'lossLocation'
 *      lossState: // value for 'lossState'
 *      lossDate: // value for 'lossDate'
 *      injuriesInvolved: // value for 'injuriesInvolved'
 *      police: // value for 'police'
 *      reporter: // value for 'reporter'
 *      insuredVehicles: // value for 'insuredVehicles'
 *      otherVehicles: // value for 'otherVehicles'
 *      insuredName: // value for 'insuredName'
 *      lineOfBusiness: // value for 'lineOfBusiness'
 *      attachmentKeys: // value for 'attachmentKeys'
 *   },
 * });
 */
export function useCreateFnolMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFnolMutation,
    CreateFnolMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateFnolMutation, CreateFnolMutationVariables>(
    CreateFnolDocument,
    options,
  );
}
export type CreateFnolMutationHookResult = ReturnType<
  typeof useCreateFnolMutation
>;
export type CreateFnolMutationResult =
  Apollo.MutationResult<CreateFnolMutation>;
export type CreateFnolMutationOptions = Apollo.BaseMutationOptions<
  CreateFnolMutation,
  CreateFnolMutationVariables
>;
export const ReportsDocument = gql`
  query reports {
    fnols {
      clientClaimNumber
      createdAt
      id
      incidentDescription
      lossDatetime
      lossLocation
      lossState
      nonVehiclePropertyDamageDescription
      noticeType
      policeAgencyName
      policeReportNumber
      policyNumber
      createdBy
      status
    }
  }
`;

/**
 * __useReportsQuery__
 *
 * To run a query within a React component, call `useReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useReportsQuery(
  baseOptions?: Apollo.QueryHookOptions<ReportsQuery, ReportsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReportsQuery, ReportsQueryVariables>(
    ReportsDocument,
    options,
  );
}
export function useReportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportsQuery,
    ReportsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReportsQuery, ReportsQueryVariables>(
    ReportsDocument,
    options,
  );
}
export type ReportsQueryHookResult = ReturnType<typeof useReportsQuery>;
export type ReportsLazyQueryHookResult = ReturnType<typeof useReportsLazyQuery>;
export type ReportsQueryResult = Apollo.QueryResult<
  ReportsQuery,
  ReportsQueryVariables
>;
export const DriversDocument = gql`
  query Drivers(
    $dotNumber: string!
    $startTime: Time
    $endTime: Time
    $mileagesStartTime: Time
  ) {
    fleetSafetyReport(id: $dotNumber) {
      id
      dotNumber
      telematicsRiskVinPercentiles(startTime: $startTime, endTime: $endTime) {
        vin
        scores {
          score
          timestamp
          telematicsAssignments {
            ...telematicsAssignment
          }
        }
      }
      inspections(startDate: $startTime, endDate: $endTime) {
        inspectionID
        violations {
          oosIndicator
          totalSeverityWeight
        }
      }
      datagovInspections(startDate: $startTime, endDate: $endTime) {
        violations {
          id
          severity
          oos
        }
        telematicsAssignments {
          ...telematicsAssignment
        }
        vehicles {
          vin
          make
        }
        countyName
        inspectionDate
        id
      }
      VehiclesStatsList(startTime: $startTime, endTime: $endTime) {
        vehicles {
          vehicleTSPName
          vIN
          vehicleName
        }
      }
      telematicsVehicles(endTime: $endTime, startTime: $mileagesStartTime) {
        vin
        mileage {
          distanceMiles
        }
      }
    }
  }
  ${TelematicsAssignmentFragmentDoc}
`;

/**
 * __useDriversQuery__
 *
 * To run a query within a React component, call `useDriversQuery` and pass it any options that fit your needs.
 * When your component renders, `useDriversQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDriversQuery({
 *   variables: {
 *      dotNumber: // value for 'dotNumber'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      mileagesStartTime: // value for 'mileagesStartTime'
 *   },
 * });
 */
export function useDriversQuery(
  baseOptions: Apollo.QueryHookOptions<DriversQuery, DriversQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DriversQuery, DriversQueryVariables>(
    DriversDocument,
    options,
  );
}
export function useDriversLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DriversQuery,
    DriversQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DriversQuery, DriversQueryVariables>(
    DriversDocument,
    options,
  );
}
export type DriversQueryHookResult = ReturnType<typeof useDriversQuery>;
export type DriversLazyQueryHookResult = ReturnType<typeof useDriversLazyQuery>;
export type DriversQueryResult = Apollo.QueryResult<
  DriversQuery,
  DriversQueryVariables
>;
export const FlagsDocument = gql`
  query flags($reportId: string!, $count: int64, $severity: FlagSeverity) {
    fleetSafetyReport(id: $reportId) {
      id
      flags(
        sortBy: "severity"
        sortOrder: desc
        first: $count
        severity: $severity
      ) {
        pageInfo {
          pages
          hasNextPage
          hasPrevPage
          startCursor
          endCursor
        }
        totalCount
        edges {
          node {
            id
            severity
            title
            category
            description
            chart {
              title
              url
              table {
                records
              }
            }
          }
          cursor
        }
      }
    }
  }
`;

/**
 * __useFlagsQuery__
 *
 * To run a query within a React component, call `useFlagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFlagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFlagsQuery({
 *   variables: {
 *      reportId: // value for 'reportId'
 *      count: // value for 'count'
 *      severity: // value for 'severity'
 *   },
 * });
 */
export function useFlagsQuery(
  baseOptions: Apollo.QueryHookOptions<FlagsQuery, FlagsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FlagsQuery, FlagsQueryVariables>(
    FlagsDocument,
    options,
  );
}
export function useFlagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FlagsQuery, FlagsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FlagsQuery, FlagsQueryVariables>(
    FlagsDocument,
    options,
  );
}
export type FlagsQueryHookResult = ReturnType<typeof useFlagsQuery>;
export type FlagsLazyQueryHookResult = ReturnType<typeof useFlagsLazyQuery>;
export type FlagsQueryResult = Apollo.QueryResult<
  FlagsQuery,
  FlagsQueryVariables
>;
export const GetFlagsCountBySeverityDocument = gql`
  query getFlagsCountBySeverity($reportId: string!) {
    fleetSafetyReport(id: $reportId) {
      id
      Critical: flags(first: 0, severity: Critical) {
        totalCount
      }
      Minor: flags(first: 0, severity: Minor) {
        totalCount
      }
      Moderate: flags(first: 0, severity: Moderate) {
        totalCount
      }
      all: flags(first: 0) {
        totalCount
      }
    }
  }
`;

/**
 * __useGetFlagsCountBySeverityQuery__
 *
 * To run a query within a React component, call `useGetFlagsCountBySeverityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFlagsCountBySeverityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFlagsCountBySeverityQuery({
 *   variables: {
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function useGetFlagsCountBySeverityQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFlagsCountBySeverityQuery,
    GetFlagsCountBySeverityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetFlagsCountBySeverityQuery,
    GetFlagsCountBySeverityQueryVariables
  >(GetFlagsCountBySeverityDocument, options);
}
export function useGetFlagsCountBySeverityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFlagsCountBySeverityQuery,
    GetFlagsCountBySeverityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFlagsCountBySeverityQuery,
    GetFlagsCountBySeverityQueryVariables
  >(GetFlagsCountBySeverityDocument, options);
}
export type GetFlagsCountBySeverityQueryHookResult = ReturnType<
  typeof useGetFlagsCountBySeverityQuery
>;
export type GetFlagsCountBySeverityLazyQueryHookResult = ReturnType<
  typeof useGetFlagsCountBySeverityLazyQuery
>;
export type GetFlagsCountBySeverityQueryResult = Apollo.QueryResult<
  GetFlagsCountBySeverityQuery,
  GetFlagsCountBySeverityQueryVariables
>;
export const GetFlagsCountByCategoryDocument = gql`
  query getFlagsCountByCategory($reportId: string!) {
    fleetSafetyReport(id: $reportId) {
      id
      all: flags(first: 0) {
        totalCount
      }
      Basics: flags(first: 0, category: BASICS) {
        totalCount
      }
      RelatedEntities: flags(first: 0, category: RelatedEntities) {
        totalCount
      }
      Crashes: flags(first: 0, category: Crashes) {
        totalCount
      }
      Insurance: flags(first: 0, category: Insurance) {
        totalCount
      }
      Violations: flags(first: 0, category: Violations) {
        totalCount
      }
      DotRating: flags(first: 0, category: DOTRating) {
        totalCount
      }
    }
  }
`;

/**
 * __useGetFlagsCountByCategoryQuery__
 *
 * To run a query within a React component, call `useGetFlagsCountByCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFlagsCountByCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFlagsCountByCategoryQuery({
 *   variables: {
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function useGetFlagsCountByCategoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFlagsCountByCategoryQuery,
    GetFlagsCountByCategoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetFlagsCountByCategoryQuery,
    GetFlagsCountByCategoryQueryVariables
  >(GetFlagsCountByCategoryDocument, options);
}
export function useGetFlagsCountByCategoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFlagsCountByCategoryQuery,
    GetFlagsCountByCategoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFlagsCountByCategoryQuery,
    GetFlagsCountByCategoryQueryVariables
  >(GetFlagsCountByCategoryDocument, options);
}
export type GetFlagsCountByCategoryQueryHookResult = ReturnType<
  typeof useGetFlagsCountByCategoryQuery
>;
export type GetFlagsCountByCategoryLazyQueryHookResult = ReturnType<
  typeof useGetFlagsCountByCategoryLazyQuery
>;
export type GetFlagsCountByCategoryQueryResult = Apollo.QueryResult<
  GetFlagsCountByCategoryQuery,
  GetFlagsCountByCategoryQueryVariables
>;
export const GetViolationMapDataDocument = gql`
  query getViolationMapData(
    $reportId: string!
    $violationType: string!
    $fromTimestamp: Time
    $toTimestamp: Time
  ) {
    fleetSafetyReport(id: $reportId) {
      id
      dotNumber
      fmcsaAuthStatus
      basicScores {
        scoreDate
      }
      LocationStats(
        countType: $violationType
        startTime: $fromTimestamp
        endTime: $toTimestamp
      ) {
        features {
          type
          properties {
            name
            countyCode
            violation_count
            violation_percentage
            inspection_count
            inspection_percentage
            inspectionRecords {
              violations {
                category
              }
            }
          }
          geometry {
            type
            coordinates
          }
        }
      }
    }
  }
`;

/**
 * __useGetViolationMapDataQuery__
 *
 * To run a query within a React component, call `useGetViolationMapDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetViolationMapDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetViolationMapDataQuery({
 *   variables: {
 *      reportId: // value for 'reportId'
 *      violationType: // value for 'violationType'
 *      fromTimestamp: // value for 'fromTimestamp'
 *      toTimestamp: // value for 'toTimestamp'
 *   },
 * });
 */
export function useGetViolationMapDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetViolationMapDataQuery,
    GetViolationMapDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetViolationMapDataQuery,
    GetViolationMapDataQueryVariables
  >(GetViolationMapDataDocument, options);
}
export function useGetViolationMapDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetViolationMapDataQuery,
    GetViolationMapDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetViolationMapDataQuery,
    GetViolationMapDataQueryVariables
  >(GetViolationMapDataDocument, options);
}
export type GetViolationMapDataQueryHookResult = ReturnType<
  typeof useGetViolationMapDataQuery
>;
export type GetViolationMapDataLazyQueryHookResult = ReturnType<
  typeof useGetViolationMapDataLazyQuery
>;
export type GetViolationMapDataQueryResult = Apollo.QueryResult<
  GetViolationMapDataQuery,
  GetViolationMapDataQueryVariables
>;
export const FleetSearchDocument = gql`
  query fleetSearch($searchText: string!) {
    fetchFleetSafetySearch(searchText: $searchText) {
      dotNumber
      name
      state
      lastViewedAt
    }
  }
`;

/**
 * __useFleetSearchQuery__
 *
 * To run a query within a React component, call `useFleetSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useFleetSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFleetSearchQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useFleetSearchQuery(
  baseOptions: Apollo.QueryHookOptions<
    FleetSearchQuery,
    FleetSearchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FleetSearchQuery, FleetSearchQueryVariables>(
    FleetSearchDocument,
    options,
  );
}
export function useFleetSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FleetSearchQuery,
    FleetSearchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FleetSearchQuery, FleetSearchQueryVariables>(
    FleetSearchDocument,
    options,
  );
}
export type FleetSearchQueryHookResult = ReturnType<typeof useFleetSearchQuery>;
export type FleetSearchLazyQueryHookResult = ReturnType<
  typeof useFleetSearchLazyQuery
>;
export type FleetSearchQueryResult = Apollo.QueryResult<
  FleetSearchQuery,
  FleetSearchQueryVariables
>;
export const SearchHistoryDocument = gql`
  query SearchHistory {
    fleetSafetyReports(sortBy: "createdAt", sortOrder: desc) {
      pageInfo {
        pages
        hasNextPage
        hasPrevPage
        startCursor
        endCursor
      }
      totalCount
      edges {
        node {
          id
          name
          dotNumber
          createdAt
          starred
        }
        cursor
      }
    }
  }
`;

/**
 * __useSearchHistoryQuery__
 *
 * To run a query within a React component, call `useSearchHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchHistoryQuery({
 *   variables: {
 *   },
 * });
 */
export function useSearchHistoryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SearchHistoryQuery,
    SearchHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchHistoryQuery, SearchHistoryQueryVariables>(
    SearchHistoryDocument,
    options,
  );
}
export function useSearchHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchHistoryQuery,
    SearchHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchHistoryQuery, SearchHistoryQueryVariables>(
    SearchHistoryDocument,
    options,
  );
}
export type SearchHistoryQueryHookResult = ReturnType<
  typeof useSearchHistoryQuery
>;
export type SearchHistoryLazyQueryHookResult = ReturnType<
  typeof useSearchHistoryLazyQuery
>;
export type SearchHistoryQueryResult = Apollo.QueryResult<
  SearchHistoryQuery,
  SearchHistoryQueryVariables
>;
export const GetBasicRecommendationDocument = gql`
  query getBasicRecommendation($reportId: string!) {
    fleetSafetyReport(id: $reportId) {
      id
      recommendations {
        edges {
          node {
            sections {
              reasons {
                vINs
                link
                weightage
                violationCount
                violationGroup
                basicCategory
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetBasicRecommendationQuery__
 *
 * To run a query within a React component, call `useGetBasicRecommendationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBasicRecommendationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBasicRecommendationQuery({
 *   variables: {
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function useGetBasicRecommendationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBasicRecommendationQuery,
    GetBasicRecommendationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetBasicRecommendationQuery,
    GetBasicRecommendationQueryVariables
  >(GetBasicRecommendationDocument, options);
}
export function useGetBasicRecommendationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBasicRecommendationQuery,
    GetBasicRecommendationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBasicRecommendationQuery,
    GetBasicRecommendationQueryVariables
  >(GetBasicRecommendationDocument, options);
}
export type GetBasicRecommendationQueryHookResult = ReturnType<
  typeof useGetBasicRecommendationQuery
>;
export type GetBasicRecommendationLazyQueryHookResult = ReturnType<
  typeof useGetBasicRecommendationLazyQuery
>;
export type GetBasicRecommendationQueryResult = Apollo.QueryResult<
  GetBasicRecommendationQuery,
  GetBasicRecommendationQueryVariables
>;
export const GetBasicScoresDocument = gql`
  query getBasicScores(
    $reportId: string!
    $fromTimestamp: Time
    $toTimestamp: Time
  ) {
    fleetSafetyReport(id: $reportId) {
      id
      dotNumber
      basicScores(fromTimestamp: $fromTimestamp, toTimestamp: $toTimestamp) {
        ...BasicScores
      }
    }
  }
  ${BasicScoresFragmentDoc}
`;

/**
 * __useGetBasicScoresQuery__
 *
 * To run a query within a React component, call `useGetBasicScoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBasicScoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBasicScoresQuery({
 *   variables: {
 *      reportId: // value for 'reportId'
 *      fromTimestamp: // value for 'fromTimestamp'
 *      toTimestamp: // value for 'toTimestamp'
 *   },
 * });
 */
export function useGetBasicScoresQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBasicScoresQuery,
    GetBasicScoresQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBasicScoresQuery, GetBasicScoresQueryVariables>(
    GetBasicScoresDocument,
    options,
  );
}
export function useGetBasicScoresLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBasicScoresQuery,
    GetBasicScoresQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBasicScoresQuery, GetBasicScoresQueryVariables>(
    GetBasicScoresDocument,
    options,
  );
}
export type GetBasicScoresQueryHookResult = ReturnType<
  typeof useGetBasicScoresQuery
>;
export type GetBasicScoresLazyQueryHookResult = ReturnType<
  typeof useGetBasicScoresLazyQuery
>;
export type GetBasicScoresQueryResult = Apollo.QueryResult<
  GetBasicScoresQuery,
  GetBasicScoresQueryVariables
>;
export const FlagsOverviewDocument = gql`
  query flagsOverview($reportId: string!, $count: int64) {
    fleetSafetyReport(id: $reportId) {
      id
      ...countsBySeverity
      flags(sortBy: "severity", sortOrder: desc, first: $count) {
        edges {
          node {
            id
            severity
            title
            category
          }
        }
      }
    }
  }
  ${CountsBySeverityFragmentDoc}
`;

/**
 * __useFlagsOverviewQuery__
 *
 * To run a query within a React component, call `useFlagsOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useFlagsOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFlagsOverviewQuery({
 *   variables: {
 *      reportId: // value for 'reportId'
 *      count: // value for 'count'
 *   },
 * });
 */
export function useFlagsOverviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    FlagsOverviewQuery,
    FlagsOverviewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FlagsOverviewQuery, FlagsOverviewQueryVariables>(
    FlagsOverviewDocument,
    options,
  );
}
export function useFlagsOverviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FlagsOverviewQuery,
    FlagsOverviewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FlagsOverviewQuery, FlagsOverviewQueryVariables>(
    FlagsOverviewDocument,
    options,
  );
}
export type FlagsOverviewQueryHookResult = ReturnType<
  typeof useFlagsOverviewQuery
>;
export type FlagsOverviewLazyQueryHookResult = ReturnType<
  typeof useFlagsOverviewLazyQuery
>;
export type FlagsOverviewQueryResult = Apollo.QueryResult<
  FlagsOverviewQuery,
  FlagsOverviewQueryVariables
>;
export const FleetRatingDocument = gql`
  query fleetRating(
    $reportId: string!
    $fromTimestamp: Time
    $toTimestamp: Time
  ) {
    fleetSafetyReport(id: $reportId) {
      id
      dotNumber
      basicScores(fromTimestamp: $fromTimestamp, toTimestamp: $toTimestamp) {
        ...BasicScores
      }
      issScores(fromTimestamp: $fromTimestamp, toTimestamp: $toTimestamp) {
        ...IssScores
      }
    }
  }
  ${BasicScoresFragmentDoc}
  ${IssScoresFragmentDoc}
`;

/**
 * __useFleetRatingQuery__
 *
 * To run a query within a React component, call `useFleetRatingQuery` and pass it any options that fit your needs.
 * When your component renders, `useFleetRatingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFleetRatingQuery({
 *   variables: {
 *      reportId: // value for 'reportId'
 *      fromTimestamp: // value for 'fromTimestamp'
 *      toTimestamp: // value for 'toTimestamp'
 *   },
 * });
 */
export function useFleetRatingQuery(
  baseOptions: Apollo.QueryHookOptions<
    FleetRatingQuery,
    FleetRatingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FleetRatingQuery, FleetRatingQueryVariables>(
    FleetRatingDocument,
    options,
  );
}
export function useFleetRatingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FleetRatingQuery,
    FleetRatingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FleetRatingQuery, FleetRatingQueryVariables>(
    FleetRatingDocument,
    options,
  );
}
export type FleetRatingQueryHookResult = ReturnType<typeof useFleetRatingQuery>;
export type FleetRatingLazyQueryHookResult = ReturnType<
  typeof useFleetRatingLazyQuery
>;
export type FleetRatingQueryResult = Apollo.QueryResult<
  FleetRatingQuery,
  FleetRatingQueryVariables
>;
export const PrintSafetyReportDocument = gql`
  mutation PrintSafetyReport($reportID: string!) {
    printFleetSafetyReport(reportID: $reportID) {
      id
      url
    }
  }
`;
export type PrintSafetyReportMutationFn = Apollo.MutationFunction<
  PrintSafetyReportMutation,
  PrintSafetyReportMutationVariables
>;

/**
 * __usePrintSafetyReportMutation__
 *
 * To run a mutation, you first call `usePrintSafetyReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePrintSafetyReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [printSafetyReportMutation, { data, loading, error }] = usePrintSafetyReportMutation({
 *   variables: {
 *      reportID: // value for 'reportID'
 *   },
 * });
 */
export function usePrintSafetyReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PrintSafetyReportMutation,
    PrintSafetyReportMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PrintSafetyReportMutation,
    PrintSafetyReportMutationVariables
  >(PrintSafetyReportDocument, options);
}
export type PrintSafetyReportMutationHookResult = ReturnType<
  typeof usePrintSafetyReportMutation
>;
export type PrintSafetyReportMutationResult =
  Apollo.MutationResult<PrintSafetyReportMutation>;
export type PrintSafetyReportMutationOptions = Apollo.BaseMutationOptions<
  PrintSafetyReportMutation,
  PrintSafetyReportMutationVariables
>;
export const RecommendationsOverviewDocument = gql`
  query recommendationsOverview($reportId: string!, $count: int64) {
    fleetSafetyReport(id: $reportId) {
      id
      recommendations(sortBy: "impact", sortOrder: desc, first: $count) {
        edges {
          node {
            id
            impact
            title
          }
        }
      }
    }
  }
`;

/**
 * __useRecommendationsOverviewQuery__
 *
 * To run a query within a React component, call `useRecommendationsOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecommendationsOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecommendationsOverviewQuery({
 *   variables: {
 *      reportId: // value for 'reportId'
 *      count: // value for 'count'
 *   },
 * });
 */
export function useRecommendationsOverviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    RecommendationsOverviewQuery,
    RecommendationsOverviewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RecommendationsOverviewQuery,
    RecommendationsOverviewQueryVariables
  >(RecommendationsOverviewDocument, options);
}
export function useRecommendationsOverviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RecommendationsOverviewQuery,
    RecommendationsOverviewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RecommendationsOverviewQuery,
    RecommendationsOverviewQueryVariables
  >(RecommendationsOverviewDocument, options);
}
export type RecommendationsOverviewQueryHookResult = ReturnType<
  typeof useRecommendationsOverviewQuery
>;
export type RecommendationsOverviewLazyQueryHookResult = ReturnType<
  typeof useRecommendationsOverviewLazyQuery
>;
export type RecommendationsOverviewQueryResult = Apollo.QueryResult<
  RecommendationsOverviewQuery,
  RecommendationsOverviewQueryVariables
>;
export const ShareSafetyReportDocument = gql`
  mutation ShareSafetyReport($reportID: string!) {
    shareSafetyReport(id: $reportID) {
      id
      expiresAt
    }
  }
`;
export type ShareSafetyReportMutationFn = Apollo.MutationFunction<
  ShareSafetyReportMutation,
  ShareSafetyReportMutationVariables
>;

/**
 * __useShareSafetyReportMutation__
 *
 * To run a mutation, you first call `useShareSafetyReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareSafetyReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareSafetyReportMutation, { data, loading, error }] = useShareSafetyReportMutation({
 *   variables: {
 *      reportID: // value for 'reportID'
 *   },
 * });
 */
export function useShareSafetyReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ShareSafetyReportMutation,
    ShareSafetyReportMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ShareSafetyReportMutation,
    ShareSafetyReportMutationVariables
  >(ShareSafetyReportDocument, options);
}
export type ShareSafetyReportMutationHookResult = ReturnType<
  typeof useShareSafetyReportMutation
>;
export type ShareSafetyReportMutationResult =
  Apollo.MutationResult<ShareSafetyReportMutation>;
export type ShareSafetyReportMutationOptions = Apollo.BaseMutationOptions<
  ShareSafetyReportMutation,
  ShareSafetyReportMutationVariables
>;
export const TelematicsRiskScoreDocument = gql`
  query telematicsRiskScore(
    $reportId: string!
    $fromTimestamp: Time
    $toTimestamp: Time
  ) {
    fleetSafetyReport(id: $reportId) {
      id
      dotNumber
      hasTelematicsConnection
      TspProvider
      telematicsRiskFleetPercentiles(
        startTime: $fromTimestamp
        endTime: $toTimestamp
      ) {
        score
        timestamp
        isConfidentScore
        lowConfidenceReason
      }
    }
  }
`;

/**
 * __useTelematicsRiskScoreQuery__
 *
 * To run a query within a React component, call `useTelematicsRiskScoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useTelematicsRiskScoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTelematicsRiskScoreQuery({
 *   variables: {
 *      reportId: // value for 'reportId'
 *      fromTimestamp: // value for 'fromTimestamp'
 *      toTimestamp: // value for 'toTimestamp'
 *   },
 * });
 */
export function useTelematicsRiskScoreQuery(
  baseOptions: Apollo.QueryHookOptions<
    TelematicsRiskScoreQuery,
    TelematicsRiskScoreQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TelematicsRiskScoreQuery,
    TelematicsRiskScoreQueryVariables
  >(TelematicsRiskScoreDocument, options);
}
export function useTelematicsRiskScoreLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TelematicsRiskScoreQuery,
    TelematicsRiskScoreQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TelematicsRiskScoreQuery,
    TelematicsRiskScoreQueryVariables
  >(TelematicsRiskScoreDocument, options);
}
export type TelematicsRiskScoreQueryHookResult = ReturnType<
  typeof useTelematicsRiskScoreQuery
>;
export type TelematicsRiskScoreLazyQueryHookResult = ReturnType<
  typeof useTelematicsRiskScoreLazyQuery
>;
export type TelematicsRiskScoreQueryResult = Apollo.QueryResult<
  TelematicsRiskScoreQuery,
  TelematicsRiskScoreQueryVariables
>;
export const AccidentDocument = gql`
  query Accident($reportId: string!) {
    fleetSafetyReport(id: $reportId) {
      id
      basicScores {
        scoreDate
      }
      OOSSummary {
        category
        inspections
        oOSViolations
        nationalAverage
        oOSPercent
      }
    }
  }
`;

/**
 * __useAccidentQuery__
 *
 * To run a query within a React component, call `useAccidentQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccidentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccidentQuery({
 *   variables: {
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function useAccidentQuery(
  baseOptions: Apollo.QueryHookOptions<AccidentQuery, AccidentQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccidentQuery, AccidentQueryVariables>(
    AccidentDocument,
    options,
  );
}
export function useAccidentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccidentQuery,
    AccidentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccidentQuery, AccidentQueryVariables>(
    AccidentDocument,
    options,
  );
}
export type AccidentQueryHookResult = ReturnType<typeof useAccidentQuery>;
export type AccidentLazyQueryHookResult = ReturnType<
  typeof useAccidentLazyQuery
>;
export type AccidentQueryResult = Apollo.QueryResult<
  AccidentQuery,
  AccidentQueryVariables
>;
export const DotRatingDocument = gql`
  query DotRating($reportId: string!) {
    fleetSafetyReport(id: $reportId) {
      id
      DotRating
    }
  }
`;

/**
 * __useDotRatingQuery__
 *
 * To run a query within a React component, call `useDotRatingQuery` and pass it any options that fit your needs.
 * When your component renders, `useDotRatingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDotRatingQuery({
 *   variables: {
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function useDotRatingQuery(
  baseOptions: Apollo.QueryHookOptions<DotRatingQuery, DotRatingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DotRatingQuery, DotRatingQueryVariables>(
    DotRatingDocument,
    options,
  );
}
export function useDotRatingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DotRatingQuery,
    DotRatingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DotRatingQuery, DotRatingQueryVariables>(
    DotRatingDocument,
    options,
  );
}
export type DotRatingQueryHookResult = ReturnType<typeof useDotRatingQuery>;
export type DotRatingLazyQueryHookResult = ReturnType<
  typeof useDotRatingLazyQuery
>;
export type DotRatingQueryResult = Apollo.QueryResult<
  DotRatingQuery,
  DotRatingQueryVariables
>;
export const InsuranceDocument = gql`
  query Insurance($reportId: string!) {
    fleetSafetyReport(id: $reportId) {
      id
      InsuranceSummary {
        status
        types
        carrier
        policyNumber
        effectiveDateTo
        effectiveDateFrom
      }
    }
  }
`;

/**
 * __useInsuranceQuery__
 *
 * To run a query within a React component, call `useInsuranceQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsuranceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsuranceQuery({
 *   variables: {
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function useInsuranceQuery(
  baseOptions: Apollo.QueryHookOptions<InsuranceQuery, InsuranceQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InsuranceQuery, InsuranceQueryVariables>(
    InsuranceDocument,
    options,
  );
}
export function useInsuranceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InsuranceQuery,
    InsuranceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InsuranceQuery, InsuranceQueryVariables>(
    InsuranceDocument,
    options,
  );
}
export type InsuranceQueryHookResult = ReturnType<typeof useInsuranceQuery>;
export type InsuranceLazyQueryHookResult = ReturnType<
  typeof useInsuranceLazyQuery
>;
export type InsuranceQueryResult = Apollo.QueryResult<
  InsuranceQuery,
  InsuranceQueryVariables
>;
export const ShippersDocument = gql`
  query Shippers($reportId: string!) {
    fleetSafetyReport(id: $reportId) {
      id
      ShipperList {
        shipperName
        inspectionsCount
      }
    }
  }
`;

/**
 * __useShippersQuery__
 *
 * To run a query within a React component, call `useShippersQuery` and pass it any options that fit your needs.
 * When your component renders, `useShippersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShippersQuery({
 *   variables: {
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function useShippersQuery(
  baseOptions: Apollo.QueryHookOptions<ShippersQuery, ShippersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ShippersQuery, ShippersQueryVariables>(
    ShippersDocument,
    options,
  );
}
export function useShippersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ShippersQuery,
    ShippersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ShippersQuery, ShippersQueryVariables>(
    ShippersDocument,
    options,
  );
}
export type ShippersQueryHookResult = ReturnType<typeof useShippersQuery>;
export type ShippersLazyQueryHookResult = ReturnType<
  typeof useShippersLazyQuery
>;
export type ShippersQueryResult = Apollo.QueryResult<
  ShippersQuery,
  ShippersQueryVariables
>;
export const TrendsDocument = gql`
  query Trends($reportId: string!) {
    fleetSafetyReport(id: $reportId) {
      id
      GeneralTrends {
        year
        month
        pU
        utilization
        mileage
      }
    }
  }
`;

/**
 * __useTrendsQuery__
 *
 * To run a query within a React component, call `useTrendsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrendsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrendsQuery({
 *   variables: {
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function useTrendsQuery(
  baseOptions: Apollo.QueryHookOptions<TrendsQuery, TrendsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TrendsQuery, TrendsQueryVariables>(
    TrendsDocument,
    options,
  );
}
export function useTrendsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TrendsQuery, TrendsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TrendsQuery, TrendsQueryVariables>(
    TrendsDocument,
    options,
  );
}
export type TrendsQueryHookResult = ReturnType<typeof useTrendsQuery>;
export type TrendsLazyQueryHookResult = ReturnType<typeof useTrendsLazyQuery>;
export type TrendsQueryResult = Apollo.QueryResult<
  TrendsQuery,
  TrendsQueryVariables
>;
export const RecommendationsDocument = gql`
  query recommendations($reportId: string!, $count: int64) {
    fleetSafetyReport(id: $reportId) {
      id
      recommendations(sortBy: "impact", sortOrder: desc, first: $count) {
        pageInfo {
          pages
          hasNextPage
          hasPrevPage
          startCursor
          endCursor
        }
        totalCount
        edges {
          node {
            id
            engine
            impact
            title
            sections {
              markdown
              chart {
                url
                geoJSON {
                  features {
                    type
                    properties {
                      name
                      violation_count
                      violation_percentage
                    }
                    geometry {
                      type
                      coordinates
                    }
                  }
                }
                title
                projection {
                  data {
                    xVal
                    yVals
                  }
                  threshold
                  xLabel
                  yLabel
                  lineConfigs {
                    name
                    dashed
                  }
                }
              }
            }
          }
          cursor
        }
      }
    }
  }
`;

/**
 * __useRecommendationsQuery__
 *
 * To run a query within a React component, call `useRecommendationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecommendationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecommendationsQuery({
 *   variables: {
 *      reportId: // value for 'reportId'
 *      count: // value for 'count'
 *   },
 * });
 */
export function useRecommendationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    RecommendationsQuery,
    RecommendationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RecommendationsQuery, RecommendationsQueryVariables>(
    RecommendationsDocument,
    options,
  );
}
export function useRecommendationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RecommendationsQuery,
    RecommendationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RecommendationsQuery,
    RecommendationsQueryVariables
  >(RecommendationsDocument, options);
}
export type RecommendationsQueryHookResult = ReturnType<
  typeof useRecommendationsQuery
>;
export type RecommendationsLazyQueryHookResult = ReturnType<
  typeof useRecommendationsLazyQuery
>;
export type RecommendationsQueryResult = Apollo.QueryResult<
  RecommendationsQuery,
  RecommendationsQueryVariables
>;
export const GetUserNotificationPreferencesDocument = gql`
  query getUserNotificationPreferences {
    userNotificationPreferences {
      email
      sms
      workflow
    }
  }
`;

/**
 * __useGetUserNotificationPreferencesQuery__
 *
 * To run a query within a React component, call `useGetUserNotificationPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserNotificationPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserNotificationPreferencesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserNotificationPreferencesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserNotificationPreferencesQuery,
    GetUserNotificationPreferencesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserNotificationPreferencesQuery,
    GetUserNotificationPreferencesQueryVariables
  >(GetUserNotificationPreferencesDocument, options);
}
export function useGetUserNotificationPreferencesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserNotificationPreferencesQuery,
    GetUserNotificationPreferencesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserNotificationPreferencesQuery,
    GetUserNotificationPreferencesQueryVariables
  >(GetUserNotificationPreferencesDocument, options);
}
export type GetUserNotificationPreferencesQueryHookResult = ReturnType<
  typeof useGetUserNotificationPreferencesQuery
>;
export type GetUserNotificationPreferencesLazyQueryHookResult = ReturnType<
  typeof useGetUserNotificationPreferencesLazyQuery
>;
export type GetUserNotificationPreferencesQueryResult = Apollo.QueryResult<
  GetUserNotificationPreferencesQuery,
  GetUserNotificationPreferencesQueryVariables
>;
export const GetUserContactInfoDocument = gql`
  query getUserContactInfo($userId: string) {
    user(id: $userId) {
      id
      firstName
      lastName
      phoneNumber
    }
  }
`;

/**
 * __useGetUserContactInfoQuery__
 *
 * To run a query within a React component, call `useGetUserContactInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserContactInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserContactInfoQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserContactInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserContactInfoQuery,
    GetUserContactInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserContactInfoQuery,
    GetUserContactInfoQueryVariables
  >(GetUserContactInfoDocument, options);
}
export function useGetUserContactInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserContactInfoQuery,
    GetUserContactInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserContactInfoQuery,
    GetUserContactInfoQueryVariables
  >(GetUserContactInfoDocument, options);
}
export type GetUserContactInfoQueryHookResult = ReturnType<
  typeof useGetUserContactInfoQuery
>;
export type GetUserContactInfoLazyQueryHookResult = ReturnType<
  typeof useGetUserContactInfoLazyQuery
>;
export type GetUserContactInfoQueryResult = Apollo.QueryResult<
  GetUserContactInfoQuery,
  GetUserContactInfoQueryVariables
>;
export const UpdateUserNotificationPreferencesDocument = gql`
  mutation updateUserNotificationPreferences(
    $preferences: [UserWorkflowPreference_InputObject!]!
  ) {
    updateUserNotificationPreferences(preferences: $preferences) {
      workflow
      email
      sms
    }
  }
`;
export type UpdateUserNotificationPreferencesMutationFn =
  Apollo.MutationFunction<
    UpdateUserNotificationPreferencesMutation,
    UpdateUserNotificationPreferencesMutationVariables
  >;

/**
 * __useUpdateUserNotificationPreferencesMutation__
 *
 * To run a mutation, you first call `useUpdateUserNotificationPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserNotificationPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserNotificationPreferencesMutation, { data, loading, error }] = useUpdateUserNotificationPreferencesMutation({
 *   variables: {
 *      preferences: // value for 'preferences'
 *   },
 * });
 */
export function useUpdateUserNotificationPreferencesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserNotificationPreferencesMutation,
    UpdateUserNotificationPreferencesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserNotificationPreferencesMutation,
    UpdateUserNotificationPreferencesMutationVariables
  >(UpdateUserNotificationPreferencesDocument, options);
}
export type UpdateUserNotificationPreferencesMutationHookResult = ReturnType<
  typeof useUpdateUserNotificationPreferencesMutation
>;
export type UpdateUserNotificationPreferencesMutationResult =
  Apollo.MutationResult<UpdateUserNotificationPreferencesMutation>;
export type UpdateUserNotificationPreferencesMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateUserNotificationPreferencesMutation,
    UpdateUserNotificationPreferencesMutationVariables
  >;
export const UpdateUserContactInfoDocument = gql`
  mutation UpdateUserContactInfo(
    $id: string!
    $email: string
    $firstName: string
    $lastName: string
    $phoneNumber: string
  ) {
    patchUser(
      id: $id
      email: $email
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
    ) {
      id
      email
      firstName
      lastName
      phoneNumber
    }
  }
`;
export type UpdateUserContactInfoMutationFn = Apollo.MutationFunction<
  UpdateUserContactInfoMutation,
  UpdateUserContactInfoMutationVariables
>;

/**
 * __useUpdateUserContactInfoMutation__
 *
 * To run a mutation, you first call `useUpdateUserContactInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserContactInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserContactInfoMutation, { data, loading, error }] = useUpdateUserContactInfoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useUpdateUserContactInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserContactInfoMutation,
    UpdateUserContactInfoMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserContactInfoMutation,
    UpdateUserContactInfoMutationVariables
  >(UpdateUserContactInfoDocument, options);
}
export type UpdateUserContactInfoMutationHookResult = ReturnType<
  typeof useUpdateUserContactInfoMutation
>;
export type UpdateUserContactInfoMutationResult =
  Apollo.MutationResult<UpdateUserContactInfoMutation>;
export type UpdateUserContactInfoMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserContactInfoMutation,
  UpdateUserContactInfoMutationVariables
>;
export const FinalizeTelematicsDocument = gql`
  mutation FinalizeTelematics(
    $authCode: string!
    $error: string
    $scope: string
    $state: string!
    $tsp: TSP!
  ) {
    finalizeTelematics(
      authCode: $authCode
      error: $error
      scope: $scope
      state: $state
      tsp: $tsp
    ) {
      error
      handleID
      safetyReportID
    }
  }
`;
export type FinalizeTelematicsMutationFn = Apollo.MutationFunction<
  FinalizeTelematicsMutation,
  FinalizeTelematicsMutationVariables
>;

/**
 * __useFinalizeTelematicsMutation__
 *
 * To run a mutation, you first call `useFinalizeTelematicsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinalizeTelematicsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finalizeTelematicsMutation, { data, loading, error }] = useFinalizeTelematicsMutation({
 *   variables: {
 *      authCode: // value for 'authCode'
 *      error: // value for 'error'
 *      scope: // value for 'scope'
 *      state: // value for 'state'
 *      tsp: // value for 'tsp'
 *   },
 * });
 */
export function useFinalizeTelematicsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FinalizeTelematicsMutation,
    FinalizeTelematicsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    FinalizeTelematicsMutation,
    FinalizeTelematicsMutationVariables
  >(FinalizeTelematicsDocument, options);
}
export type FinalizeTelematicsMutationHookResult = ReturnType<
  typeof useFinalizeTelematicsMutation
>;
export type FinalizeTelematicsMutationResult =
  Apollo.MutationResult<FinalizeTelematicsMutation>;
export type FinalizeTelematicsMutationOptions = Apollo.BaseMutationOptions<
  FinalizeTelematicsMutation,
  FinalizeTelematicsMutationVariables
>;
export const VehiclesDocument = gql`
  query vehicles(
    $reportId: string!
    $fromTimestamp: Time
    $toTimestamp: Time
    $telematicsStartTime: Time
    $telematicsEndTime: Time
  ) {
    fleetSafetyReport(id: $reportId) {
      id
      hasTelematicsConnection
      dotNumber
      VehiclesStatsList(startTime: $fromTimestamp, endTime: $toTimestamp) {
        vehicles {
          vehicleTSPName
          vIN
          riskScore
          vehicleName
          violationSeverityWeight
          violationSeverityTimeWeighted
          keyBehaviour
        }
      }
      telematicsRiskVinPercentiles(
        startTime: $telematicsStartTime
        endTime: $telematicsEndTime
      ) {
        vin
        scores {
          score
          timestamp
          telematicsAssignments {
            assignedDurationMs
            driver {
              id
              name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useVehiclesQuery__
 *
 * To run a query within a React component, call `useVehiclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehiclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehiclesQuery({
 *   variables: {
 *      reportId: // value for 'reportId'
 *      fromTimestamp: // value for 'fromTimestamp'
 *      toTimestamp: // value for 'toTimestamp'
 *      telematicsStartTime: // value for 'telematicsStartTime'
 *      telematicsEndTime: // value for 'telematicsEndTime'
 *   },
 * });
 */
export function useVehiclesQuery(
  baseOptions: Apollo.QueryHookOptions<VehiclesQuery, VehiclesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VehiclesQuery, VehiclesQueryVariables>(
    VehiclesDocument,
    options,
  );
}
export function useVehiclesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VehiclesQuery,
    VehiclesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VehiclesQuery, VehiclesQueryVariables>(
    VehiclesDocument,
    options,
  );
}
export type VehiclesQueryHookResult = ReturnType<typeof useVehiclesQuery>;
export type VehiclesLazyQueryHookResult = ReturnType<
  typeof useVehiclesLazyQuery
>;
export type VehiclesQueryResult = Apollo.QueryResult<
  VehiclesQuery,
  VehiclesQueryVariables
>;
export const CompleteDriverViolationFetchDocument = gql`
  mutation CompleteDriverViolationFetch(
    $twoFA: string!
    $browserWSEndpoint: string!
    $reportID: string!
    $twoFactorUrl: string!
  ) {
    completeDriverViolationFetch(
      browserWSEndpoint: $browserWSEndpoint
      twoFA: $twoFA
      reportId: $reportID
      twoFactorUrl: $twoFactorUrl
    )
  }
`;
export type CompleteDriverViolationFetchMutationFn = Apollo.MutationFunction<
  CompleteDriverViolationFetchMutation,
  CompleteDriverViolationFetchMutationVariables
>;

/**
 * __useCompleteDriverViolationFetchMutation__
 *
 * To run a mutation, you first call `useCompleteDriverViolationFetchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteDriverViolationFetchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeDriverViolationFetchMutation, { data, loading, error }] = useCompleteDriverViolationFetchMutation({
 *   variables: {
 *      twoFA: // value for 'twoFA'
 *      browserWSEndpoint: // value for 'browserWSEndpoint'
 *      reportID: // value for 'reportID'
 *      twoFactorUrl: // value for 'twoFactorUrl'
 *   },
 * });
 */
export function useCompleteDriverViolationFetchMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteDriverViolationFetchMutation,
    CompleteDriverViolationFetchMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompleteDriverViolationFetchMutation,
    CompleteDriverViolationFetchMutationVariables
  >(CompleteDriverViolationFetchDocument, options);
}
export type CompleteDriverViolationFetchMutationHookResult = ReturnType<
  typeof useCompleteDriverViolationFetchMutation
>;
export type CompleteDriverViolationFetchMutationResult =
  Apollo.MutationResult<CompleteDriverViolationFetchMutation>;
export type CompleteDriverViolationFetchMutationOptions =
  Apollo.BaseMutationOptions<
    CompleteDriverViolationFetchMutation,
    CompleteDriverViolationFetchMutationVariables
  >;
export const InitiateDriverViolationFetchDocument = gql`
  query InitiateDriverViolationFetch(
    $username: string!
    $password: string!
    $reportId: string!
  ) {
    initiateDriverViolationFetch(
      reportId: $reportId
      username: $username
      password: $password
    ) {
      browserWSEndpoint
      twoFactorUrl
    }
  }
`;

/**
 * __useInitiateDriverViolationFetchQuery__
 *
 * To run a query within a React component, call `useInitiateDriverViolationFetchQuery` and pass it any options that fit your needs.
 * When your component renders, `useInitiateDriverViolationFetchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInitiateDriverViolationFetchQuery({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function useInitiateDriverViolationFetchQuery(
  baseOptions: Apollo.QueryHookOptions<
    InitiateDriverViolationFetchQuery,
    InitiateDriverViolationFetchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    InitiateDriverViolationFetchQuery,
    InitiateDriverViolationFetchQueryVariables
  >(InitiateDriverViolationFetchDocument, options);
}
export function useInitiateDriverViolationFetchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InitiateDriverViolationFetchQuery,
    InitiateDriverViolationFetchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InitiateDriverViolationFetchQuery,
    InitiateDriverViolationFetchQueryVariables
  >(InitiateDriverViolationFetchDocument, options);
}
export type InitiateDriverViolationFetchQueryHookResult = ReturnType<
  typeof useInitiateDriverViolationFetchQuery
>;
export type InitiateDriverViolationFetchLazyQueryHookResult = ReturnType<
  typeof useInitiateDriverViolationFetchLazyQuery
>;
export type InitiateDriverViolationFetchQueryResult = Apollo.QueryResult<
  InitiateDriverViolationFetchQuery,
  InitiateDriverViolationFetchQueryVariables
>;
export const ViolationsDocument = gql`
  query violations(
    $reportId: string!
    $fromTimestamp: Time
    $toTimestamp: Time
    $datagovStartDate: Time
    $datagovEndDate: Time
  ) {
    fleetSafetyReport(id: $reportId) {
      id
      dotNumber
      fmcsaAuthStatus
      inspections(startDate: $fromTimestamp, endDate: $toTimestamp) {
        rowID
        inspectionID
        inspectionDate
        countyCode
        countyName
        location
        violations {
          rowID
          violationID
          code
          category
          oosIndicator
          timeWeight
          severityWeight
          totalSeverityWeight
          humanReadableCode
          description
          isDSMS
          oosWeight
          publishedDate
          group {
            name
            category
            severity
            humanReadable
          }
        }
        vehicles {
          vin
        }
        publicVINs
        driver {
          id
          name
          licenseNumber
        }
      }
      datagovInspections(
        startDate: $datagovStartDate
        endDate: $datagovEndDate
      ) {
        id
        inspectionDate
        location
        locationDesc
        countyCode
        countyName
        countyCodeState
        violTotal
        violations {
          id
          vehicle {
            id
            vin
          }
          oos
          severity
          partNumber
          partNumberSection
          code
          group {
            name
            category
            severity
            humanReadable
          }
          description
          seqNumber
        }
        vehicles {
          id
          vin
          make
          company
          licensePlate
          licenseState
        }
      }
    }
  }
`;

/**
 * __useViolationsQuery__
 *
 * To run a query within a React component, call `useViolationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useViolationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViolationsQuery({
 *   variables: {
 *      reportId: // value for 'reportId'
 *      fromTimestamp: // value for 'fromTimestamp'
 *      toTimestamp: // value for 'toTimestamp'
 *      datagovStartDate: // value for 'datagovStartDate'
 *      datagovEndDate: // value for 'datagovEndDate'
 *   },
 * });
 */
export function useViolationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ViolationsQuery,
    ViolationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ViolationsQuery, ViolationsQueryVariables>(
    ViolationsDocument,
    options,
  );
}
export function useViolationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ViolationsQuery,
    ViolationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ViolationsQuery, ViolationsQueryVariables>(
    ViolationsDocument,
    options,
  );
}
export type ViolationsQueryHookResult = ReturnType<typeof useViolationsQuery>;
export type ViolationsLazyQueryHookResult = ReturnType<
  typeof useViolationsLazyQuery
>;
export type ViolationsQueryResult = Apollo.QueryResult<
  ViolationsQuery,
  ViolationsQueryVariables
>;
