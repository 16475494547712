import { QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import { persistWithIndexedDb } from './persist-with-indexed-db';

const queryCache = new QueryCache();
const queryClient = new QueryClient({
  queryCache,
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
persistWithIndexedDb(queryClient);
export { queryClient, QueryClientProvider };
