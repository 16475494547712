import { useEffect } from 'react';
import useAuthContext from 'src/hooks/use-auth-context';
import { useAnalytics } from 'src/utils/analytics';
import { Feature, useFeatureEnabled } from 'src/utils/feature-flags';

export default function Posthog() {
  const { user } = useAuthContext();
  const isPosthogEnabled = useFeatureEnabled(Feature.UW_POSTHOG, false);
  const { enableCapture, disableCapture, identify } = useAnalytics();

  useEffect(() => {
    if (user && user.id) {
      if (!isPosthogEnabled) {
        disableCapture();
      } else {
        enableCapture();
        identify(user);
      }
    }
  }, [disableCapture, enableCapture, identify, isPosthogEnabled, user]);

  return null;
}
