import { useRoutes } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';

import { useLightTheme } from 'src/assets/themes';
import { routes } from './routes';

const cache = createCache({
  key: 'css',
  prepend: true,
});

export default function AllRoutes() {
  const theme = useLightTheme();
  const allRoutes = useRoutes(routes);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <CacheProvider value={cache}>
        <SnackbarProvider
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          {allRoutes}
        </SnackbarProvider>
      </CacheProvider>
    </ThemeProvider>
  );
}
