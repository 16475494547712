import { usePostHog } from 'posthog-js/react';
import { UserProfileResponse } from '@nirvana/api/auth';

export const PosthogEvents = {
  FLEET_APPLICATION_OPENED: 'FLEET_APPLICATION_OPENED',

  // List filtes
  RECOMMENDED_ACTION_FILTER_APPLIED: 'RECOMMENDED_ACTION_FILTER_APPLIED',

  // Panel Reviews
  OVERVIEW_PANEL_REVIEWED: 'OVERVIEW_PANEL_REVIEWED',
  OVERVIEW_PANEL_UNREVIEWED: 'OVERVIEW_PANEL_UNREVIEWED',

  OPERATION_PANEL_REVIEWED: 'OPERATION_PANEL_REVIEWED',
  OPERATION_PANEL_UNREVIEWED: 'OPERATION_PANEL_UNREVIEWED',

  EQUIPMENTS_PANEL_REVIEWED: 'EQUIPMENTS_PANEL_REVIEWED',
  EQUIPMENTS_PANEL_UNREVIEWED: 'EQUIPMENTS_PANEL_UNREVIEWED',

  DRIVERS_PANEL_REVIEWED: 'DRIVERS_PANEL_REVIEWED',
  DRIVERS_PANEL_UNREVIEWED: 'DRIVERS_PANEL_UNREVIEWED',

  SAFETY_PANEL_REVIEWED: 'SAFETY_PANEL_REVIEWED',
  SAFETY_PANEL_UNREVIEWED: 'SAFETY_PANEL_UNREVIEWED',

  FINANCIALS_PANEL_REVIEWED: 'FINANCIALS_PANEL_REVIEWED',
  FINANCIALS_PANEL_UNREVIEWED: 'FINANCIALS_PANEL_UNREVIEWED',

  LOSSES_PANEL_REVIEWED: 'LOSSES_PANEL_REVIEWED',
  LOSSES_PANEL_UNREVIEWED: 'LOSSES_PANEL_UNREVIEWED',

  PACKAGES_PANEL_REVIEWED: 'PACKAGES_PANEL_REVIEWED',
  PACKAGES_PANEL_UNREVIEWED: 'PACKAGES_PANEL_UNREVIEWED',

  // Tasks events
  TASKS_DIALOG_OPENED: 'TASK_DIALOG_OPENED',
  TASKS_SUBMITTED: 'TASKS_SUBMITTED',

  // Projected Miles
  PROJECTED_MILES_UPDATED: 'PROJECTED_MILES_UPDATED',

  // Driver Events
  DRIVER_MVR_PULLED: 'DRIVER_MVR_PULLED',
  DRIVER_MVR_REFRESHED: 'DRIVER_MVR_REFRESHED',
  DRIVER_ATTRACT_SCORE_FETCHED: 'DRIVER_ATTRACT_SCORE_FETCHED',
  DRIVER_CREATED: 'DRIVER_CREATED',
  DRIVER_DELETED: 'DRIVER_DELETED',
  DRIVER_UPDATED: 'DRIVER_UPDATED',

  // Spotlight events
  SPOTLIGHT_OPENED: 'SPOTLIGHT_OPENED',
  FLEET_APPLICATION_OPENED_FROM_SPOTLIGHT:
    'FLEET_APPLICATION_OPENED_FROM_SPOTLIGHT',
  NF_APPLICATION_OPENED_FROM_SPOTLIGHT: 'NF_APPLICATION_OPENED_FROM_SPOTLIGHT',
  ENDORSEMENT_OPENED_FROM_SPOTLIGHT: 'ENDORSEMENT_OPENED_FROM_SPOTLIGHT',

  TARGET_PRICE_SUBMITTED: 'TARGET_PRICE_SUBMITTED',
};

export const useAnalytics = () => {
  const posthog = usePostHog();

  const identify = (user: UserProfileResponse) => {
    posthog?.identify(user.id, user);
  };

  const resetIdentity = () => {
    posthog?.reset();
  };

  const enableCapture = () => {
    posthog?.opt_in_capturing();
  };

  const disableCapture = () => {
    posthog?.opt_out_capturing();
  };

  const capture = (eventName: string, properties?: Record<string, any>) => {
    if (!posthog?.has_opted_out_capturing()) {
      posthog?.capture(eventName, properties);
    }
  };

  return {
    identify,
    resetIdentity,
    disableCapture,
    enableCapture,
    capture,
  };
};
