import { cookieStorage } from '@nirvana/ui-kit';
import axios from 'axios';
import { AuthApi, Configuration } from 'src/../../api/auth';

const configOptions = new Configuration();
const apiService = new AuthApi(configOptions);

axios.interceptors.request.use((config) => {
  config.headers.JSESSIONID = cookieStorage.get({
    key: import.meta.env.VITE_AUTH_TOKEN,
  });

  return config;
});

export default apiService;
